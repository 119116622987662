export class brandModel {
	constructor(data) {
		this.update(data || {});
	}

	update(value) {
		this.id = value.id || 0;
		this.key = value.key;
		this.name = value.name;
		this.logoId = value.logoId || '';
		this.typeId = value.typeId || null;
		this.type = value.type || '';
	}
}
