import Vue from 'vue';
import EventBus from '@/event-bus';

const logError = function (type, msg, details) {
	var data = {
		type: type,
		message: msg,
		details: details,
		url: document.location.href
	};
	EventBus.$emit('error', data);
	try {
		Vue.webApi.post('error', 'log', data);
	} catch (ex) {
		// do nothin
	}
};

if (!(process.env && process.env.VUE_APP_IS_DEVELOPMENT === "true")) {
	Vue.config.errorHandler = function (err, vm, info) {
		logError("Vue", err.toString(), {
			error: {
				message: err.message,
				stack: err.stack
			},
			info: info,
			browser: vm.$browserDetect.meta
		});
	};

	window.onerror = function (msg, src, linenum, colnum, error) {
		logError("Window", msg, {
			src,
			linenum,
			colnum,
			error
		});
	};
}
