window.isDevelopment = process.env && process.env.VUE_APP_IS_DEVELOPMENT === "true";

//********* core components
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import Vue from 'vue';
import axios from 'axios';
import BootstrapVue from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import Loading from 'vue-loading-overlay';
import VueToastr from '@deveodk/vue-toastr';
import AsyncComputed from 'vue-async-computed';
import browserDetect from 'vue-browser-detect-plugin';
import UniqueId from 'vue-unique-id';

//********* local plugins
import store from './store';
import webApi from '@/plugins/webApiHelper';
import settingsHelper from '@/plugins/settingsHelper';
import { router } from '@/_helpers';
import '@/_helpers/polyfill.closest.js';

//********* external components styles
import 'vue-loading-overlay/dist/vue-loading.css';

//********* Components
import App from '@/app.vue';

//********* attach plugins
Vue.use(webApi, axios.create());
Vue.use(settingsHelper);
Vue.use(BootstrapVue);
Vue.use(AsyncComputed);
Vue.use(UniqueId);
Vue.use(Vuelidate);
Vue.use(browserDetect);
Vue.use(Loading, {
	canCancel: false,
	color: '#b50518',
	loader: 'spinner'
});
Vue.use(VueToastr, {
	defaultPosition: 'toast-bottom-right',
	defaultType: 'success',
	defaultTimeout: 3000
});

//********* import filters
import '@/_helpers/filters';

const requireComponent = require.context(
	// The relative path of the components folder
	'@/components/_global',
	// Whether or not to look in subfolders
	false,
	// The regular expression used to match base component filenames
	/[A-Za-z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach(fileName => {
	// Get component config
	const componentConfig = requireComponent(fileName);

	// Get PascalCase name of component
	const componentName = upperFirst(
		camelCase(
			// Gets the file name regardless of folder depth
			fileName
				.split('/')
				.pop()
				.replace(/\.\w+$/, '')
		)
	);


	// Register component globally
	Vue.component(
		componentName,
		// Look for the component options on `.default`, which will
		// exist if the component was exported with `export default`,
		// otherwise fall back to module's root.
		componentConfig.default || componentConfig
	);
});

// attach the error handler
import '@/plugins/errorHandler.js';

// add css
import '@/css/site.scss';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
