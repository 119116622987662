import Vue from 'vue';
import { OptionTypes, TextTypes } from '@/_helpers';
import { textModel } from '@/_models';

export class caravanOptionModel {
	constructor(data, configId) {
		this.update(data || {}, configId);
		this.uiId = 'opt_' + Math.floor(Math.random() * (1000000));
	}

	update(value, configId) {
		this.id = value.id || 0;
		this.readonly = value.readonly || false;
		if (this.text) {
			this.text.update(value.text);
		} else {
			this.text = new textModel(value.text || {
				type: TextTypes.Option
			});
		}
		this.configId = configId || this.configId;
		this.additionalText = value.additionalText || null;
		this.currency = value.currency || Vue.$s.currency;
		this.price = value.price;
		this.weight = value.weight;
		this.value = value.value || null;
		this.deleted = value.deleted || false;
		this.selected = value.selected || false;
		this.priceViewText = value.priceViewText;
		this.weightTotal = value.weightTotal || null;

		// other props
		this.focus = value.focus || false;
		this.additionalType = value.additionalType || 0;
		this.excludeOptions = value.excludeOptions || [];
		this.changeType(value.type);

		// local id
		this.localId = value.localId || this.localId;
		if (this.configId && !this.localId) {
			this.localId = 'c_' + this.configId + '_o_' + (this.id && this.id > 0 ? this.id : Math.floor(Math.random() * (1000000)));
		}

		this._displayCurrency = null;
	}

	get correctPrice() {
		return Vue.$s.getPrice(this.currency, this.price, this.displayCurrency);
	}

	get displayCurrency() {
		return this._displayCurrency || Vue.$s.currency;
	}
	set displayCurrency(value) {
		this._displayCurrency = value;
	}

	changeType(type) {
		this.type = type || OptionTypes.Normal;
		if (this.type === OptionTypes.OnDemand) {
			this.hasPrice = !!this.price;
		} else {
			this.hasPrice = [OptionTypes.Normal, OptionTypes.MainPrice].indexOf(this.type) !== -1;
		}
		this.availableTypes = this.readonly ? [this.type] : [OptionTypes.Normal, OptionTypes.Included, OptionTypes.OnDemand, OptionTypes.NoExtraCharge];
	}

	toggleAddiontalText() {
		this.hasAdditionalText = !this.hasAdditionalText;
	}

	toggleDelete() {
		this.deleted = !this.deleted;
	}

	priceText() {
		switch (this.type) {
			case OptionTypes.Included:
				return 'Serienausstattung';
			case OptionTypes.OnDemand:
				return 'auf Anfrage';
			case OptionTypes.NoExtraCharge:
				return 'ohne Aufpreis';
			default:
				return '';
		}
	}

	updatePrice(value) {
		this.currency = value.currency;
		this.price = value.price;
		this.changeType(this.type);
	}
}
