<template>
	<img :src="src" v-if="url && src"
		 :class="classes"
		 :style="styles"
		 :alt="alt"
		 :title="title"
		 />
</template>
<script>
	import Vue from 'vue';

	export default {
		name: 'auth-image',
		props: {
			url: String,
			classes: String,
			styles: String,
			alt: String,
			title: String,
		    disabled: Boolean
		},
		data() {
			return {
				dataUrl: null
			};
		},
		computed: {
			src() {
				if (this.url && !this.dataUrl) {
					Vue.webApi.downloadImage(this.url)
						.then(dataUrl => {
							this.dataUrl = dataUrl;
						});
				}
				return this.dataUrl;
			}
		},
		watch: {
			url: {
				handler() {
					this.dataUrl = null;
				},
				immediate: true
			}
		}
	};
</script>
