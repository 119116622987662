import numeral from 'numeral';

// load a locale
numeral.register('locale', 'de-ch', {
	delimiters: {
		thousands: '\'',
		decimal: '.'
	},
	abbreviations: {
		thousand: 'k',
		million: 'm',
		billion: 'b',
		trillion: 't'
	},
	ordinal: () => {
		return '.';
	},
	currency: {
		symbol: 'CHF'
	}
});

// switch between locales
numeral.locale('de-ch');

export const formatter = {
	append,
	prepend,
	number,
	replaceEmpty,
	currency,
	decimal
};

function append(value, append, noSpace) {
	if (!value) return '';
	if (!append) return value;

	return value.toString() + (noSpace ? '' : ' ') + append;
}

function prepend(value, prepend, noSpace) {
	if (!value) return '';
	if (!prepend) return value;

	return prepend + (noSpace ? '' : ' ') + value.toString();
}

function number(value, format) {
	if (!value) return '';

	format = format || '0,0';
	return numeral(value).format(format);
}

function replaceEmpty(value, replaceWith) {
	if (value) {
		return value;
	} else {
		return replaceWith;
	}
}

function currency(price, currency, emptyText, negative) {
	if (price) {
		if (price < 0) {
			negative = true;
			price = price * -1;
		}
		return (negative ? '- ' : '') + append(prepend(number(price), currency), '.-', true);
	} else {
		return emptyText || '';
	}
}

function decimal(value, appendText, emptyText) {
	if (value) {
		return append(number(value, '0,0.[0000]'), appendText);
	} else {
		return emptyText || '';
	}
}
