<template>
	<b-form-group :id="id"
				  label-cols="3"
				  :label="label"
				  :state="validation && validation.$dirty ? !validation.$error : null"
				  :label-for="id && 'input-' + id">
		<b-input-group :prepend="prepend" :append="append" :class="{ 'is-invalid': validation && validation.$dirty && !!validation.$error }">
			<b-form-select :id="id && 'input-' + id"
						   ref="select"
						   :value="model"
						   :options="options"
						   :class="[inputCols ? 'col-' + inputCols : '']"
						   @input="changed"
						   :disabled="readonly"
						   :state="validation && validation.$dirty ? !validation.$error : null"
						   :aria-describedby="'input-' + id + '-feedback'">
				<template v-if="firstOptionText" slot="first">
					<option :value="null" disabled>{{ firstOptionText }}</option>
				</template>
			</b-form-select>
		</b-input-group>
		<b-form-invalid-feedback :id="id && 'input-' + id + '-feedback'">
			<slot name="error">{{ errorMessage }}</slot>
		</b-form-invalid-feedback>
		<slot name="help-text"></slot>
	</b-form-group>
</template>
<script>
	export default {
		name: 'form-group-select',
		model: {
			prop: 'model',
			event: 'model'
		},
		props: {
			id: String,
			label: String,
			model: {
				required: true
			},
			validation: Object,
			errorMessage: String,
			focus: Boolean,
			readonly: {
				type: Boolean,
				default: false
			},
			inputCols: [String, Number],
			prepend: String,
			append: String,
			options: Array,
			firstOptionText: String
		},
		created() {
			this.$on('setFocus', this.setFocus);
		},
		beforeDestroy() {
			this.$off('setFocus', this.setFocus);
		},
		mounted() {
			if (this.focus) {
				this.$nextTick(() => { // wait till everything (like dialog animations) are done
					this.$nextTick(() => {
						this.$refs['select'].focus();
					});
				});
			}
		},
		methods: {
			changed(value) {
				this.$emit('model', value)
			},
			setFocus() {
				this.$nextTick(() => {
					this.$nextTick(() => {
						var target = 'select';
						this.$refs[target] && this.$refs[target].focus && this.$refs[target].focus();
					});
				});
			}
		}
	};
</script>
