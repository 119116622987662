export const TextTypes = {
	NotRelevant: -1,
	NotSet: 0,
	ExtraItem: 1,
	BasicData: 2,
	Category: 3,
	Option: 4,
	OptionModelName: 5,
	Package: 6,
	Country: 7,
    General: 8,
	PackageOption: 9,
	ConditionLabel: 10,
	DeliveryOptionLabel: 11,

	getText,
	getList
};

function getText(type) {
	switch (type) {
		case TextTypes.NotRelevant:
			return null;
		case TextTypes.NotSet:
			return 'Texttyp wählen';
		case TextTypes.ExtraItem:
			return 'Zusatz-Einbauten';
		case TextTypes.BasicData:
			return 'Basisdaten';
		case TextTypes.Category:
			return 'Kategoriename';
		case TextTypes.Option:
			return 'Optionname';
		case TextTypes.OptionModelName:
			return 'Modelname';
		case TextTypes.Package:
			return 'Paketname';
		case TextTypes.Country:
			return 'Land';
		case TextTypes.General:
			return 'Allgemein';
		case TextTypes.PackageOption:
			return 'Paket-Optionname';
		case TextTypes.ConditionLabel:
			return 'Kondition';
		case TextTypes.DeliveryOptionLabel:
			return 'Lieferoption';
		default:
			console.warn('TextTypes not know');
			return 'Unknown';
	}
}

function getList(addNotSet) {
	var result = [
		TextTypes.NotSet,
		TextTypes.ExtraItem,
		TextTypes.BasicData,
		TextTypes.Category,
		TextTypes.Option,
		//TextTypes.OptionModelName,
		TextTypes.Package,
		TextTypes.Country,
		TextTypes.General,
		TextTypes.PackageOption,
		TextTypes.ConditionLabel,
		TextTypes.DeliveryOptionLabel,
	];
	if (!addNotSet)
		result.splice(0, 1);

	return result.map(t => {
		return {
			value: t,
			text: getText(t)
		};
	});
}
