import { requestHeader } from '@/_helpers';
import EventBus from '@/event-bus';

const webApi = {
	_installed: false,
	_axios: null,
	_contentCache: {},
	_contentRequests: {},
	_vm: null,
	constructUrl(controller, action, query) {
		if (query) {
			if (query && typeof query !== 'string') {
				let newQuery = [];
				for (const prop in query) {
					if (Object.prototype.hasOwnProperty.call(query, prop)) {
						newQuery.push(`${encodeURIComponent(prop)}=${query[prop] === null ? '' : encodeURIComponent(query[prop])}`);
					}
				}
				query = newQuery.join('&');
			}

		}
		return '/api/' + controller + '/' + action + '/' + (query ? '?' + query : '');
	},
	headers() {
		return {
			headers: {
				...requestHeader.authHeader(),
				'Content-Type': 'application/json'
			}
		};
	},
	get(controller, action, data, query) {
		return this.call(controller, action, data, 'get', query);
	},
	post(controller, action, data, query) {
		return this.call(controller, action, data, 'post', query);
	},
	put(controller, action, data, query) {
		return this.call(controller, action, data, 'put', query);
	},
	delete(controller, action, data, query) {
		return this.call(controller, action, data, 'delete', query);
	},
	call(controller, action, data, method, query) {
		return new Promise((resolve, reject) => {
			this._axios({
				method: (method || 'get').toLowerCase(),
				url: this.constructUrl(controller, action, query),
				data: data,
				...this.headers()
			})
				.then(
					response => {
						var data = response.data;
						delete response.data;
						if (data.settings) {
							EventBus.$emit('update-settings', data.settings);
						}
						resolve(data, response);
					}, error => {
						reject(error);
					}
				);
		});
	},
	downloadImage(url) {
		return new Promise((resolve, reject) => {
			this._axios({
				method: 'get',
				url: url,
				responseType: 'arraybuffer',
				...this.headers()
			})
				.then(response => {
					var arr = new Uint8Array(response.data);
					var raw = '';
					arr.forEach(byte => {
						raw += String.fromCharCode(byte);
					});
					var b64 = btoa(raw);
					var dataURL = 'data:' + (response.headers['content-type'] || 'image/jpeg') + ';base64,' + b64;
					return resolve(dataURL);
				}, error => {
					reject(error);
				});
		});
	},
	install(Vue, axios) {
		if (this._installed) {
			return;
		}
		this._installed = true;
		this._vm = Vue;

		if (!axios) {
			console.error('You have to install axios');
			return;
		}

		Vue.axios = axios;
		this._axios = axios;

		// add global error handling
		this._axios.interceptors.response.use(response => response, function (error) {
			if (error && error.response && error.response.status === 403) {
				EventBus.$emit('forbidden');
			} else {
				// global error handling
				//console.error('TODO: Add global error handling for AJAX Calls', error);
			}
			return Promise.reject(error);
		});

        Vue.prototype.$webApi = this;
		Vue.webApi = this;
	}
};

export default webApi;
