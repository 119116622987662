import Vue from 'vue';

export class extraItemModel {
	constructor(data, configId) {
		this.update(data || {}, configId);
		this.uiId = 'exi_' + Math.floor(Math.random() * (1000000));
	}

	update(value, configId) {
		this.id = value.id || 0;
		this.name = value.name || '';
		this.currency = value.currency || 'CHF';
		this.price = value.price;
		this.weight = value.weight;
		this.selected = value.selected;
		this.deleted = value.deleted;
		this.hasPrice = true;
		this.configId = configId || this.configId;
		this.priceViewText = value.priceViewText;
		this.types = value.types || [];

		// local id
		this.localId = value.localId || this.localId;
		if (this.configId && !this.localId) {
			this.localId = 'c_' + this.configId + '_x_' + (this.id && this.id > 0 ? this.id : Math.floor(Math.random() * (1000000)));
		}
	}

	get correctPrice() {
		return Vue.$s.getPrice(this.currency, this.price, 'CHF');
	}

	toggleDelete() {
		this.deleted = !this.deleted;
	}

	toggleSelected() {
		this.selected = !this.selected;
	}

}

