import Vue from 'vue';
import { basicDataSelectionModel, caravanModel, mediaModel } from '@/_models';
import { merge } from 'lodash';

export class stockModel {
    constructor(data, basicDatas) {
        this.update(data || {}, basicDatas);
    }

    update(value, basicDatas) {
        this.id = value.id || 0;
        this.caravan = new caravanModel(value.caravan);
        this.name = value.name || '';
        this.chassisNumber = value.chassisNumber;
        this.inheritLayout = value.inheritLayout;
        this.layout = new mediaModel(value.layout);
        this.online = value.online;
        this.changedBy = value.changedBy;
        this.changedOn = value.changedOn;
        this.priceEUR = value.priceEUR;
        this.priceCHF = value.priceCHF;
        this.weight = value.weight;
        this.currency = value.currency || Vue.$s.currency;

        this.lengthTotal = value.lengthTotal;
        this.lengthUsage = value.lengthUsage;
        this.lengthBuild = value.lengthBuild;
        this.width = value.width;
        this.weightTotal = value.weightTotal;
        this.awningMessurement = value.awningMessurement;

        this.basicDatas = (value.basicDatas || []).filter(i => !!i.id).map(i => new basicDataSelectionModel(i, basicDatas));
    }

    addBasicData(basicData) {
        this.basicDatas.push(new basicDataSelectionModel(basicData));
    }

    toPayload() {
        let order = 1;
        const payload = merge({}, this);
        payload.basicDatas = this.basicDatas.filter(i => i.required || (i.value && !i.deleted)).map(i => ({
            id: i.id,
            value: i.value,
            order: order++
        }))
        return payload;
    }
}
