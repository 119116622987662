export const ClientStatuses = {
	Inactive: 0,
	Active: 1,
	Deleted: 9,

	getText,
	getList
};

function getText(type) {
	switch (type) {
		case ClientStatuses.Inactive:
			return 'Inaktiv';
		case ClientStatuses.Active:
			return 'Aktiv';
		default:
			console.warn('ClientStatus not know');
			return 'Unknown';
	}
}

function getList(addDeleted) {
	var result = [
		ClientStatuses.Inactive,
		ClientStatuses.Active
	];
	if (addDeleted)
		result.push(ClientStatuses.Deleted);

	return result.map(t => {
		return {
			value: t,
			text: getText(t)
		};
	});
}
