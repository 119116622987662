import moment from 'moment';

export class orderClientDataModel {
	constructor(data) {
		this.update(data || {});
	}

	update(value) {
		this.name = value.name || '';
		this.representative = value.representative || '';
		this.street = value.street || '';
		this.dob = value.dob || null;
		this.dobValid = '';
		if (this.dob) {
			this.dob = moment.utc(this.dob).utc().toDate();
		}
		this.city = value.city || '';
		this.postalCode = value.postalCode || '';
		this.homeLocation = value.homeLocation || '';
		this.telephone = value.telephone || '';
		this.email = value.email || '';
	}
}
