const state = {
	currency: 'EUR',
	exchangeRate: 1.19,
	vat: 7.7,
	version: 0,
	financeRate: 1.1928
};

const mutations = {
	update(state, settings) {
		state.currency = settings.currency;
		state.exchangeRate = settings.exchangeRate;
		state.vat = settings.vat;
		state.financeRate = settings.financeRate;
		state.version++;
	},
	updateItem(state, setting) {
		if (setting.value) {
			state[setting.key] = setting.value;
			state.version++;
		}
	},
	incrementVersion(state) {
		state.version++;
	}
};

export default {
	namespaced: true,
	state,
	mutations
};
