import Vue from 'vue';
import { formatter } from '@/_helpers';

Vue.filter('append', formatter.append);
Vue.filter('prepend', formatter.prepend);
Vue.filter('number', formatter.number);
Vue.filter('currency', formatter.currency);
Vue.filter('decimal', formatter.decimal);
Vue.filter('replaceEmpty', formatter.replaceEmpty);
Vue.filter('toBr', value => {
	var div = document.createElement('div');
	div.innerHTML = (value || '').trim();
	return div.innerHTML.replace(/\n/ig, '<br />');
});
Vue.filter('symbol', value => {
	switch (value) {
		case 'EUR':
			return '€';
		default:
			return 'Fr.';
	}
});
