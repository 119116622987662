import { filterBaseModel } from '@/_models';

export class clientFilterModel extends filterBaseModel {
	constructor(data) {
		super(data);
		this.update(data || {});
	}

	update(value) {
		super.update(value);

		this.name = value.name || '';
		this.email = value.email || '';
	}
}
