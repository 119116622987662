export class userModel {
	constructor(data) {
		this.update(data || {});
	}

	update(value) {
		this.id = value.id || 0;
		this.username = value.username;
		this.email = value.email;
		this.password = value.password;
		this.confirmPassword = value.confirmPassword;
		this.displayName = value.displayName;
		this.roles = value.roles || [];
	}
}
