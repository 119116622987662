export class filterBaseModel {
	constructor(data) {
		this.update(data || {});
	}

	update(value) {
		this.page = value.page || 1;
		this.pageSize = value.pageSize || 20;
		this.column = value.column || '';
		this.descending = value.descending || false;
	}
}
