import Vue from 'vue';
import Vuex from 'vuex';
import settings from '@/store/settings';
import lastEdited from '@/store/lastEdited';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
	modules: {
		settings,
		lastEdited
	},
	strict: debug
});
