import { typeBaseMixin } from '@/_mixins';

export const offerTypeMixin = {
	mixins: [typeBaseMixin],
	data() {
		return {
			lastEditTypeKey: 'offers'
		};
	}
};
