export const loadingMixin = {
	data() {
		return {
			loading: false,
			loader: null
		};
	},
	methods: {
		hideLoading(callback) {
			this.$nextTick(() => {
				this.$nextTick(() => {
					this.loading = false;
					callback && callback();
				});
			});
        }
    },
	watch: {
		loading: {
			handler(value) {
				if (value && !this.loader) {
					this.loader = this.$loading.show();
				} else if (this.loader) {
					this.loader.hide();
					this.loader = null;
				}
			},
			immediate: true
		}
	},
	deactivated() {
		if (this.loader) {
			this.loader.hide();
			this.loader = null;
			this.loading = false;
		}
	},
	beforeDestroy() {
		if (this.loader) {
			this.loader.hide();
			this.loader = null;
			this.loading = false;
		}
	}
};
