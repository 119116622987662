import Vue from 'vue';
import { additionalItemModel, discountModel } from '@/_models';
import _ from 'lodash';


export class totalModel {
	constructor(data) {
		this.update(data);
	}

	update(value) {
		value = value || {};

		this.subTotal = value.subTotal;
		if (this.discount) {
			this.discount.update(value.discount || {});
		} else {
			this.discount = new discountModel(value.discount);
		}
		this.calculating = false;
		this.weight = value.weight || 0;
		this._displayCurrency = null;
		this.subTotalCurrency = null;
		this.additionalItems = (value.additionalItems || []).map(i => new additionalItemModel(i, this.displayCurrency));
	}

	get displayCurrency() {
		return this._displayCurrency || Vue.$s.currency;
	}
	set displayCurrency(value) {
		this._displayCurrency = value;
		this.discount.displayCurrency = value;
		this.additionalItems.forEach(i => i.displayCurrency = value);
	}

	get correctDisplayCurrency() {
		return this.subTotalCurrency || this.displayCurrency;
	}

	get correctSubTotal() {
		if (this.correctDisplayCurrency)
			return Vue.$s.getPrice(this.correctDisplayCurrency, this.subTotal, this.displayCurrency);
		else
			return this.subTotal;
	}

	get additionalItemsBeforeTotal() {
		let price = 0;
		this.additionalItems.filter(i => i.beforeDiscount && !i.deleted).forEach(i => price += i.getPrice);
		return price;
	}

	get additionalItemsAfterTotal() {
		let price = 0;
		this.additionalItems.filter(i => !i.beforeDiscount && !i.deleted).forEach(i => price += i.getPrice);
		return price;
	}

	get discountAmount() {
		return (this.discount && this.discount.getDiscount(this.additionalItemsBeforeTotal + (this.correctSubTotal || 0))) || 0;
	}

	get discountCalculated() {
		return !this.discount.isPercentage && this.discount.value && this.discount.currency !== this.displayCurrency;
	}

	get total() {
		return (this.correctSubTotal || 0) + this.additionalItemsBeforeTotal - this.discountAmount + this.additionalItemsAfterTotal;
	}

	recalculate = _.debounce(items => {
		this.calculating = true;
		let total = 0;
		let weight = 0;
		(items || []).forEach(o => {
			if (o.hasPrice && o.price) {
				total += o.correctPrice;
			}
			weight += o.weight;
		});
		this.subTotal = total;
		this.weight = weight;
		this.calculating = false;
	}, 50);
}
