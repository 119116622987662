<template>
	<div class="container hidden-print main-nav-container">
		<div class="menu-wrapper" v-if="currentUser">
			<div class="user">
				<b-dropdown variant="dark" right>
					<template slot="button-content">
						<icon name="user" />{{ currentUser.displayName }}
					</template>
					<b-dropdown-item :to="{ name: 'editProfile' }"><icon name="user-edit" />Profil bearbeiten</b-dropdown-item>
					<b-dropdown-item :to="{ name: 'changePassword' }"><icon name="key" />Kennwort ändern</b-dropdown-item>
					<b-dropdown-divider />
					<b-dropdown-item @click="logout"><icon name="power-off" />Abmelden</b-dropdown-item>
				</b-dropdown>
			</div>

			<div class="menu clearfix">
				<ul class="clearfix">
					<li v-for="route in routes.filter(r => r.permission && r.visible)">
						<navigation-link :route="route" />
					</li>
				</ul>
				<div class="sub" v-if="subMenu">
					<ul class="clearfix">
						<li v-for="route in subMenu">
							<navigation-link :route="route" />
						</li>
					</ul>
				</div>

			</div>

		</div>
	</div>
</template>
<script>
	import { authenticationService } from '@/_services';
	import NavigationLink from './navigation-link.vue';

	export default {
		name: 'navigation',
		data() {
			return {
				currentUser: null,
				routes: []
			};
		},
		created() {
			authenticationService.currentUser.subscribe(x => this.currentUser = x);
			this.routes = this.getNavRoutes(this.$router.options.routes);
		},
		computed: {
			subMenu() {
				var active = (this.routes || []).find(r => r.permission && r.active);
				if (active) {
					var children = active.children && active.children.filter(r => r.permission && r.visible) || [];
					if (children.length) {
						return children;
					}
				}
				return null;
			}
		},
		methods: {
			logout() {
				authenticationService.logout();
				this.$router.push({ name: 'login' });
			},
			getNavRoutes(routes) {
				var result = [];

				if (routes && routes.length) {
					routes.forEach(route => {
						// get the default child, if required
						var children = route.children;
						if (!route.name && children && children.length) {
							children = children.slice(0);
							route = children[0];
							children.splice(0, 1);
							route.children = children;
						}

						if (route.name && route.meta && route.meta.nav) {
							result.push({
								title: route.meta.nav.title || route.meta.title,
								name: route.name,
								icon: route.meta.nav.icon,
								forward: route.meta.nav.forward,
								children: this.getNavRoutes(route.children),
								active: false,
								permission: false,
								visible: true,
								auth: route.meta.authorize
							});
						} else {
							result.push({
								name: route.name,
								children: this.getNavRoutes(route.children),
								active: false,
								permission: false,
								visible: false,
								auth: (route.meta || {}).authorize
							});
						}
					});
				}

				return result;
			},
			userHasRole(roles) {
				if (roles && this.currentUser) {
					if (roles.length) {
						if (this.currentUser.roles && this.currentUser.roles.length) {
							return !!this.currentUser.roles.find(r => roles.indexOf(r) !== -1);
						}
						return false;
					} else {
						return true;
					}
				} else {
					return false;
				}
			},
			updatePermissions(routes) {
				if (routes && routes.length) {
					routes.forEach(route => {
						route.permission = this.userHasRole(route.auth)
						this.updatePermissions(route.children);
					});
				}
			},
			updateActive(name, routes) {
				if (routes && routes.length) {
					routes.forEach(route => {
						this.updateActive(name, route.children);
						route.active = name === route.name || !!(route.children || []).find(c => c.active);
					});
				}
			}
		},
		watch: {
			currentUser: {
				handler() {
					this.updatePermissions(this.routes);
				},
				immediate: true,
				deep: true
			},
			'$route': {
				handler(route) {
					this.updateActive(route.name, this.routes);
				},
				immediate: true
			},
			routes: {
				handler(routes) {
					this.updatePermissions(routes);
					this.updateActive(this.$route.name, routes);
				}
			}
		},
		components: {
			NavigationLink
		}
	};
</script>
