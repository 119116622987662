export class caravanBasicDataModel {
	constructor(data) {
		this.update(data || {});
	}

	update(value) {
		this.id = value.id || 0;
		this.text = value.text || '';
		this.value = value.value || '';
		this.suffix = value.suffix || '';
		this.deleted = false;
	}
}
