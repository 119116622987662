export const domHelper = {
	createElementFromHTML,
	removeElementById
};

function createElementFromHTML(htmlString) {
	const div = document.createElement('div');
	div.innerHTML = htmlString.trim();

	// Change this to div.childNodes to support multiple top-level nodes
	return div.firstChild;
}

function removeElementById(id) {
	const element = document.getElementById(id);
	if (element) {
		element.remove();
	}
}
