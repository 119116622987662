import { typeBaseMixin } from '@/_mixins';

export const stockTypeMixin = {
	mixins: [typeBaseMixin],
	data() {
		return {
			lastEditTypeKey: 'stocks'
		};
	}
};
