import Vue from 'vue';
import { BehaviorSubject } from 'rxjs';
import EventBus from '@/event-bus';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
	login,
	logout,
	refreshToken,
	currentUser: currentUserSubject.asObservable(),
	get currentUserValue() { return currentUserSubject.value; }
};

function login(username, password) {
	return Vue.webApi.post('account', 'login', { username, password })
		.then(function (response) {
			if (response.success) {
				localStorage.setItem('currentUser', JSON.stringify(response.data));
				currentUserSubject.next(response.data);
				return response.data;
			} else {
				return Promise.reject(response.messages);
			}
		});
}

function logout() {
	// remove user from local storage to log user out
	localStorage.removeItem('currentUser');
	currentUserSubject.next(null);
}

function refreshToken(callback) {
	if (this.currentUserValue) {
		var self = this;
		return Vue.webApi.post('account', 'refreshToken')
			.then(function (response) {
				if (response.success) {
					localStorage.setItem('currentUser', JSON.stringify(response.data));
					currentUserSubject.next(response.data);
				} else {
					self.logout();
					EventBus.$emit('logout');
				}
				callback && callback();
			});
	}
}
