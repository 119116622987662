import EventBus from '@/event-bus';

export const discardChangesMixin = {
	methods: {
		hasUnsavedChanges() {
			if (webpackHotUpdate) {
				console.warn('hasUnsavedChanges was called, but should be overwritten in the component using this mixin. Called when the component needs no know if it is dirty of not');
				return true;
			}
			return false;
		}
	},
	beforeRouteLeave(to, from, next) {
		if (this.hasUnsavedChanges()) {
			EventBus.$emit('confirmLeave', next);
		} else {
			next();
		}
	}
};
