export class validationModel {
	constructor(showGoodState) {
		this._messages = [];
		this._showGoodState = showGoodState;
	}

	addMessage(key, message) {
		this._messages.push({
			key: key,
			message: message
		});
	}

	updateState(validator, debug) {
		let message = '';
		let state = null;
		debug && console.log(validator && validator.$dirty);
		if (validator && validator.$dirty) {
			state = !validator.$invalid;
			if (!this._showGoodState && state) {
				state = null;
			}
			message = (this._messages.find(v => !validator[v.key]) || {}).message || '';
			debug && console.log('msg', message);
		}
		validator.$message = message;
		validator.$state = state;
	}
};
