import { labelValueModel } from '@/_models';
import _ from 'lodash';

export class conditionsModel {
	constructor(data) {
		this.update(data || {});
	}

	update(value) {
		_.merge(this, value);
		this.clientAddress = value.clientAddress;
		this.startText = value.startText;
		this.thankYouText = value.thankYouText;
		this.signatureName = value.signatureName;
		this.location = value.location;
		this.date = value.date;
		this.conditions = (value.conditions || []).map(i => new labelValueModel(i));
		this.hideSignature = !!value.hideSignature;
		this.hasSignature = !!value.hasSignature;
	}

	get showSignature() {
		return !this.hideSignature;
	}
	set showSignature(value) {
		return this.hideSignature = !value;
	}

	addCondition() {
		this.conditions.push(new labelValueModel({}));
	}

	removeEmptyConditions() {
		this.conditions = this.conditions.filter(i => {
			return !i.deleted && ((i.label || '').trim() !== '' || (i.value || '').trim() !== '');
		});
	}
}
