<template>
	<section class="jumbotron text-center">
		<div class="container">
			<h1>Empty Route</h1>
			<h3>If you are seeing this, then a route has been defined, but the component has not yet been defined.</h3>
			<p>Path: <i>{{ currentRoute }}</i></p>
			<div v-if="routeParams.length">
				<h3>Params:</h3>
				<ul>
					<li v-for="item in routeParams">{{ item.name }}: <b>{{ item.value }}</b></li>
				</ul>
			</div>
			<div v-else>No parameters are availble</div>
			Or just <router-link to="/">go home</router-link>.
		</div>
	</section>
</template>
<script>
	export default {
		name: 'empty',
		data() {
			return {
				currentRoute: this.$route.path
			};
		},
		computed: {
			routeParams: function () {
				let result = [];
				for (var p in this.$route.params) {
					result.push({
						name: p,
						value: this.$route.params[p]
					});
				}
				return result;
			}
		},
		beforeRouteUpdate: function (to, from, next) {
			this.currentRoute = to.path;
			next();
		}
	}
</script>
