export class typeModel {
	constructor(data) {
		this.update(data || {});
	}

	update(value) {
		this.id = value.id || 0;
		this.name = value.name;
		this.singular = value.singular;
		this.requiresWeight = value.requiresWeight || false;
		this.logo = value.logo || null;
	}
}
