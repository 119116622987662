import { typeBaseMixin } from '@/_mixins';

export const textTypeMixin = {
	mixins: [typeBaseMixin],
	data() {
		return {
			lastEditTypeKey: 'texts'
		};
	}
};
