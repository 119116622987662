<template>
	<div class="container">
		<h1>Page/Route not found (aka 404)</h1>
		<h2>The page or route you have entered does not exist.</h2>
		<p>Path: <i>{{ routeNotFound }}</i></p>
		<p>Your options:</p>
		<ol>
			<li>
				Return to Home:
				<a class="btn btn-primary" @click="_goHome">Home</a><br /><br />
			</li>
			<li>
				Go back one page:
				<a class="btn btn-secondary" @click="_goBack">Back</a><br /><br />
			</li>
			<li>Use the Back button in your Browser to go to the previous page</li>
		</ol>
	</div>
</template>
<script>
	export default {
		name: 'not-found',
		data() {
			return {
				routeNotFound: this.$route.path
			};
		},
		beforeRouteUpdate: function (to, from, next) {
			this.routeNotFound = to.path;
			next();
		},
		methods: {
			_goHome: function () {
				this.$router.push({
					name: 'home'
				});
			},
			_goBack: function () {
				this.$router.back();
			}
		}
	}
</script>
