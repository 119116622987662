<template>
	<div>
		<!-- navigation and header would go here -->
		<header class="d-print-none main-header">
			<div class="container">
				<div class="header-area">
					<div id="logo" class="logo"><img src="/images/logo.png" class="img-fluid"></div>
					<div class="text">Konfigurator</div>
				</div>
			</div>
		</header>
		<div id="container-gradient" class="d-print-none"></div>
		<navigation />
		<!-- content -->
		<main role="main">
			<div class="container">
				<router-view :key="$route.fullPath"></router-view>
			</div>
		</main>
		<!-- footer elements go here -->
		<!-- drop zone "drop here" container -->
		<div class="dz-can-be-dropped">
			<span class="text"><icon name="upload" /> Sie können die Datei hier loslassen</span>
		</div>

		<b-modal ref="modal-changes" hide-footer title="Ungespeicherte Änderungen" header-text-variant="danger" size="lg">
			<div class="alert alert-danger">
				<br />
				Es hat noch ungespeicherte Änderungen auf der Seite!<br />
				<br />
				Sind Sie <b>sicher</b> dass Sie die Seite verlassen möchten und diese Änderungen <b>verwerfen</b>?
				<br />
				<br />
			</div>

			<div class="row">
				<div class="col">
					<b-button variant="danger" @click="discardChanges">Ja, die Änderungen verwerfen</b-button>
				</div>
				<div class="col-4 text-right">
					<b-button variant="outline-primary" @click="closeModal">Nein</b-button>
				</div>
			</div>
		</b-modal>
		<b-modal ref="error-dialog" header-text-variant="danger" size="lg" title="Javascrit Fehler!" ok-only ok-title="Schliessen">
			<div class="alert alert-danger">
				<h4>Es ist ein JavaScript Fehler aufgetretten!</h4>
				<br />
				Der Fehler wurde gemeldet. Bitte laden Sie die Seite neu (F5) und versuchen Sie es erneut.<br />
				<br />
				Wenn der Fehler erneut auftritt, bitte die Schritte die zum Fehler geführt haben, aufschreiben und an den Programmieren schicken.
				<br />
			</div>
		</b-modal>
	</div>
</template>
<script>
	import Vue from 'vue';
	import Navigation from '@/components/general/navigation.vue';
	import { authenticationService } from '@/_services';
	import { loadingMixin } from '@/_mixins';
	import EventBus from '@/event-bus';
	import _ from 'lodash';

	export default {
		name: 'app',
		mixins: [loadingMixin],
		data() {
			return {
				next: null,
				errorData: {}
			};
		},
		created() {
			if (window.isDevelopment) {
				var routes = this.getAllRoutes(this.$router.options.routes);
				Vue.webApi.post('routes', 'update', routes)
					.then(function () { });
			}

			EventBus.$on('logout', this.logout);
			EventBus.$on('forbidden', this.forbidden);
			EventBus.$on('confirmLeave', this.confirmLeave);

			// long loading helper
			EventBus.$on('long-load-start', this.longLoadStart);
			EventBus.$on('long-load-stop', this.longLoadStop);
			EventBus.$on('error', this.handleError);
		},
		beforeDestroy() {
			EventBus.$off('logout', this.logout);
			EventBus.$off('forbidden', this.forbidden);
			EventBus.$off('confirmLeave', this.confirmLeave);

			// long loading helper
			EventBus.$off('long-load-start', this.longLoadStart);
			EventBus.$off('long-load-stop', this.longLoadStop);
			EventBus.$off('error', this.handleError);
		},
		mounted() {
			// refresh the client token
			authenticationService.refreshToken();
		},
		methods: {
			logout() {
				this.$router.push({ name: 'login', query: { returnUrl: this.$route.path } });
			},
			forbidden() {
				this.$router.push({ name: 'unauthorized' });
			},
			confirmLeave(next) {
				this.next = next;
				this.$refs['modal-changes'].show();
			},
			longLoadStart() {
				this.loading = true;
			},
			longLoadStop() {
				this.hideLoading();
			},
			handleError(data) {
				if (!window.isDevelopment) {
					this.errorData = data;
					this.$refs['error-dialog'].show();
				}
			},
			getAllRoutes(routes) {
				if (routes) {
					var items = [];
					routes.forEach(route => {
						items.push({
							name: route.name,
							path: route.path,
							meta: route.meta,
							children: this.getAllRoutes(route.children)
						})
					});
					return items;
				} else {
					return null;
				}
			},
			discardChanges() {
				this.next && this.next();
				this.next = null;
				this.$refs['modal-changes'].hide();
			},
			closeModal() {
				this.next = null;
				this.$refs['modal-changes'].hide();
				this.$refs['error-dialog'].hide();
			}
		},
		components: {
			Navigation
		}
	};
</script>
