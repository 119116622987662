import Vue from 'vue';
import { loadingMixin } from '@/_mixins';
import { filterBaseModel } from '@/_models';

export const listMixin = {
	mixins: [loadingMixin],
	data() {
		return {
			filter: new filterBaseModel(),
			lastFilter: null,
			total: 0,
			controller: ''
		};
	},
	methods: {
		loadData(data) {
			console.warn('loadData not overwritten', data);
		},
		getFilterData(data) {
			return data;
		},
		updateSearch(force) {
			if (!this.controller) {
				console.error('Controller not specified!');
				return;
			}
			var jsonFilter = JSON.stringify(this.filter);
			if (!force && this.lastFilter === jsonFilter) {
				return;
			}
			this.loading = true;
			this.lastFilter = jsonFilter;
			Vue.webApi.post(this.controller, 'list', this.filter)
				.then(response => {
					if (this.lastFilter === jsonFilter) {
						this.loading = false;
						if (response.success) {
							this.filter.page = response.data.page;
							this.filter.pageSize = response.data.pageSize;
							this.filter.column = response.data.column;
							this.filter.descending = response.data.descending;
							this.total = response.data.total;
							this.lastFilter = JSON.stringify(this.filter);
							this.loadData(response.data.items);
						} else {
							this.$toastr('error', this.response.messages[0]);
						}
					}
				});
		},
		getLastFilter() {
			if (!this.controller) {
				console.error('Controller not specified!');
				return;
			}
			Vue.webApi.get(this.controller, 'last-filter')
				.then(response => {
					this.filter.update(this.getFilterData(response.data));
					this.updateSearch();
				});
		},
		updateSortOrder(value) {
			this.filter.column = value.column;
			this.filter.descending = value.descending;
			this.updateSearch();
		},
		updatePageSize(size) {
			this.filter.pageSize = size;
			this.updateSearch();
		},
		updatePage(page) {
			this.filter.page = page;
			this.updateSearch();
		},
		applyFilter(value) {
			this.filter.update(value);
			this.updateSearch();
		}
	},
	watch: {
		'$route': {
			handler() {
				this.loading = true;
				this.getLastFilter();
			},
			immediate: true
		}
	}
};
