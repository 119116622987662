<template>
	<b-input-group>
		<datepicker :value="value"
					@input="updated"
					name="uniquename"
					:input-class="inputClass"
					:disabled="disabled"
					:language="de"
					format="dd.MM.yyyy"
					:placeholder="placeholder"
					use-utc />

		<b-input-group-append v-if="value && !hideDelete">
			<b-button variant="danger" @click="deleteDate"><icon name="trash" no-text /></b-button>
		</b-input-group-append>
	</b-input-group>
</template>
<script>
	import Datepicker from 'vuejs-datepicker';
	import { de } from 'vuejs-datepicker/dist/locale';

	export default {
		name: 'datepika',
		model: {
			prop: 'value',
			event: 'updated'
		},
		props: {
			value: {
				required: false
			},
			disabled: Boolean,
			placeholder: String,
			hideDelete: Boolean,
			inputCols: {},
			validator: Object
		},
		data() {
			return {
				de: de
			};
		},
		computed: {
			inputClass() {
				var result = ['form-control'];
				!this.disabled && result.push('not-readonly');
				!!this.inputCols && result.push('col-' + this.inputCols);
				if (this.validator && this.validator.$dirty) {
					!!this.validator.$error && result.push('is-invalid');
					!this.validator.$error && result.push('is-valid');
				}
				return result;
			}
		},
		methods: {
			updated(value) {
				this.$emit('updated', value);
			},
			deleteDate() {
				this.$emit('updated', null);
			}
		},
		components: {
			Datepicker
		}
	};
</script>
