<template>
	<span class="type-ahead" ref="container">
		<b-input :placeholder="placeholder" :value="valueAsString" ref="input" :disabled="disabled" :state="validator && validator.$dirty ? !validator.$error : null" />
		<typeahead v-if="!disabled" v-model="currentValue" :target="target" :async-function="search" item-key="value" force-select ref="typeahead" />
		<b-form-invalid-feedback v-if="errorMessage">{{ errorMessage }}</b-form-invalid-feedback>
	</span>
</template>
<script>
	import Vue from 'vue';
	import { Typeahead } from 'uiv'
	import _ from 'lodash';
	import { caravanModel } from '@/_models';

	export default {
		name: 'model-typeahead',
		model: {
			prop: 'value',
			event: 'updated'
		},
		props: {
			value: {
				type: Object
			},
			placeholder: String,
			disabled: Boolean,
			focus: Boolean,
			validator: Object,
			errorMessage: String
		},
		data() {
			return {
				currentValue: '',
				target: null,
				lastQuery: null,
				valueAsString: ''
			};
		},
		mounted() {
			this.target = this.$refs['input'];
			this.$refs['container'].addEventListener('keydown', this.preventEnter, true);
		},
		methods: {
			search(query, done) {
				if (query === this.lastQuery) { return; }
				this.lastQuery = query;
				Vue.webApi.post('caravans', 'find', null, 'query=' + encodeURI(query))
					.then(response => {
						if (response.success && response.data.query === this.lastQuery) {
							var result = (response.data.items || [])
								.map(i => {
									return new caravanModel(i);
								});
							done(result);
						}
					});
			},
			preventEnter(ev) {
				if (ev.keyCode === 13 && this.$refs['container'] && this.$refs['container'].querySelectorAll('.open').length) {
					ev.preventDefault();
					return false;
				}
			},
			setFocus() {
				this.$nextTick(() => {
					this.$nextTick(() => {
						this.$refs['input'] && this.$refs['input'].focus();
					});
				});
			}
		},
		created() {
			this.$on('setFocus', this.setFocus);
		},
		beforeDestroy() {
			this.$off('setFocus', this.setFocus);
			this.$refs['container'].removeEventListener('keydown', this.preventEnter);
		},
		watch: {
			value: {
				handler(value) {
					if (this.currentValue !== value) {
						this.currentValue = value;
					}
				},
				immediate: true
			},
			currentValue(value) {
				if (value) {
					this.valueAsString = value.value;
				}
				this.$emit('updated', value);
			},
			focus: {
				handler(value) {
					value && this.setFocus();
				},
				immediate: true
			}
		},
		components: {
			Typeahead
		}
	};
</script>
