import Vue from 'vue';
import { caravanModel, stockModel, conditionsModel } from '@/_models';
import { OfferStatusTypes } from '@/_helpers';
import _ from 'lodash';

export class offerModel {
	constructor(data) {
		this.update(data || {});
	}

	update(value) {
		_.merge(this, value);
		this.id = value.id || 0;
		this.client = value.client;
		this.clientId = value.clientId;
		this.status = value.status || 0;
		if (this.caravan) {
			this.caravan.update(value.caravan || {});
		} else {
			this.caravan = new caravanModel(value.caravan);
		}
		this.caravanId = value.caravanId;
		if (this.stock) {
			this.stock.update(value.stock || {});
		} else {
			this.stock = new stockModel(value.stock);
		}
		this.stockId = value.stockId;
		this.currencyClient = value.currencyClient || 'CHF';
		this.vat = value.vat || Vue.$s.vat;
		this.state = value.state;
		if (this.conditions && this.conditions instanceof conditionsModel) {
			this.conditions.update(value.conditions || {});
		} else {
			this.conditions = new conditionsModel(value.conditions);
		}
		this.hideDeliveryConditions = value.hideDeliveryConditions || false;
		this.hideMassReadyToDrive = value.hideMassReadyToDrive || false;
	}

	get statusIcon() {
		return OfferStatusTypes.getIcon(this.status);
	}

	get statusText() {
		return OfferStatusTypes.getText(this.status);
	}

	stateText(state) {
		if (this.status === OfferStatusTypes.Order) {
			return 'Kaufvertrag bearbeiten';
		}
		switch (state) {
			case 1:
				return 'Konfiguration bearbeiten';
			case 2:
				return 'Anschreiben & Konditionen bearbeiten';
			default:
				return 'Offerte bearbeiten';
		}
	}

	stateIcon(state) {
		switch (state) {
			case 1:
				return 'puzzle-piece';
			case 2:
				return 'envelope-open-text';
			default:
				return 'edit';
		}
	}

	stateRoute(state) {
		if (this.status === OfferStatusTypes.Order) {
			return 'order-edit';
		}
		switch (state) {
			case 1:
				return 'offer-config';
			case 2:
				return 'offer-conditions';
			default:
				return 'offer-edit';
		}
	}

	get next() {
		return this.stateRoute(this.state + 1);
	}

	get nextIcon() {
		return this.stateIcon(this.state + 1);
	}

	get nextText() {
		return this.stateText(this.state + 1);
	}
}
