<template>
	<div class="main-title" :class="{ 'text-danger': danger }">
		<hr v-if="hrAbove" />
		<tag :is="tag">{{ text }}</tag>
		<div class="header-buttons"><slot /></div>
	</div>
</template>
<script>
	export default {
		name: 'main-title',
		props: {
			text: {
				required: true,
				type: String
			},
			tag: {
				type: String,
				default: 'h1'
			},
			hrAbove: Boolean,
			danger: Boolean
		}
	};
</script>
