<template>
	<div></div>
</template>
<script>
	import { authenticationService } from '@/_services';

	export default {
		name: 'forward',
		data() {
			return {
				currentUser: null,
				ready: false
			};
		},
		created() {
			authenticationService.currentUser.subscribe(x => this.currentUser = x);
			this.ready = true;
		},
		methods: {
			findRoute(routes, name) {
				routes = routes || this.$router.options.routes;
				var route = routes.find(r => r.name === name);
				if (route) {
					return route;
				} else {
					for (var i = 0; i < routes.length; i++) {
						if (routes[i].children) {
							route = this.findRoute(routes[i].children, name);
							if (route) {
								return route;
							}
						}
					}
				}
				return null;
			},
			userHasRole(roles) {
				if (roles && this.currentUser) {
					if (roles.length) {
						if (this.currentUser.roles && this.currentUser.roles.length) {
							return !!this.currentUser.roles.find(r => roles.indexOf(r) !== -1);
						}
						return false;
					} else {
						return true;
					}
				} else {
					return false;
				}
			}
		},
		computed: {
			route() {
				return this.ready && this.$route;
			}
		},
		watch: {
			route: {
				handler(value) {
					if (!value) { return; }

					if (value.meta && value.meta.nav && value.meta.nav.forward) {
						var route = null;
						value.meta.nav.forward.forEach(n => {
							if (!route) {
								var r = this.findRoute(null, n);
								if (r && this.userHasRole(r.meta.authorize)) {
									route = r;
								}
							}
						});
						if (route) {
							this.$router.push({ name: route.name });
							return;
						}
					}
					this.$router.push({ name: 'home' });
				},
				immediate: true
			}
		}
	};
</script>
