import Vue from 'vue';

export class discountModel {
	constructor(data) {
		this.update(data || {});
	}

	update(value) {
		this.isPercentage = value.isPercentage;
		this.text = value.text;
		this.currency = value.currency;
		this.value = value.value;
		this.displayCurrency = null;
	}

	getDiscount(total) {
		if (this.isPercentage) {
			if (this.value && total) {
				if (this.isPercentage) {
					return Math.round(total * this.value / 100);
				} else {
					return Vue.$s.getPrice(this.currency, this.value, this.displayCurrency);
				}
			}
		} else if (this.value) {
			return Vue.$s.getPrice(this.currency, this.value, this.displayCurrency);
		}
		return 0;
	}
}
