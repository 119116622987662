<template>
	<div class="inline-editor">
		<slot name="before-text" />
		<span class="text" :class="{ 'empty': isEmpty, 'readonly': readonly }" @click="textClicked">
			<span class="target" :id="id"></span>
			<slot name="text">{{ text }}</slot>
			<slot name="empty">
				<template v-if="isEmpty">
					{{ emptyText }}
				</template>
			</slot>
		</span>
		<slot name="after-text" />
		<b-popover v-if="!readonly"
				   show.sync="showing"
				   :title="title"
				   :target="id"
				   :placement="placement"
				   class="test"
				   container="iec-container"
				   ref="popover">

			<form class="input-area" :class="type" @submit="submit">
				<b-input-group :class="{ 'is-invalid': $v.$dirty && $v.$invalid }">
					<b-input-group :append="append" v-if="isText">
						<b-form-input v-model="currentValue"
									  ref="text"
									  :placeholder="placeholder"
									  :state="$v.$dirty ? !$v.$error : null" />
					</b-input-group>

					<b-form-textarea v-if="isTextArea"
									 v-model="currentValue"
									 ref="textarea"
									 :placeholder="placeholder"
									 :state="$v.$dirty ? !$v.$error : null"
									 :rows="rows" />

					<text-input-typeahead v-if="isTypeahead"
										  v-model="currentValue"
										  ref="text-typeahead"
										  :placeholder="placeholder"
										  :type="optionsNormalized.type"
										  :mode="optionsNormalized.mode"
										  :object-type="objectType"
										  :validator="$v" />

					<price v-if="isPrice"
						   ref="price"
						   :price="currentValue.price"
						   :currency="currentValue.currency"
						   :id="id"
						   :priceValidator="$v"
						   @changed="priceChanged" />

					<b-form-select v-if="isSelect"
								   ref="select"
								   v-model="currentValue"
								   :options="optionsNormalized">
						<template slot="first" v-if="firstOption">
							<option :value="null" disabled>{{ firstOption }}</option>
						</template>
					</b-form-select>

					<b-input-group-append>
						<b-button type="submit" variant="primary"><icon name="check" no-text /></b-button>
						<b-button @click="close" variant="danger"><icon name="times" no-text /></b-button>
					</b-input-group-append>
				</b-input-group>
				<b-form-invalid-feedback>
					<slot name="error">{{ errorMessage }}</slot>
				</b-form-invalid-feedback>
			</form>
		</b-popover>
	</div>
</template>
<script>
	import EventBus from '@/event-bus';
	import _ from 'lodash';
	import { integer, decimal } from '@/_validators';

	export default {
		name: 'inline-editor',
		model: {
			prop: 'value',
			event: 'updated'
		},
		props: {
			text: {},
			title: String,
			placeholder: String,
			append: String,
			rows: [String, Number],
			placement: {
				type: String,
				default: 'top'
			},
			type: {
				type: String,
				default: 'text',
				validator: function (value) {
					// The value must match one of these strings
					return ['text', 'text-typeahead', 'price', 'select', 'textarea', 'custom'].indexOf(value.toLowerCase()) !== -1
				}
			},
			value: {},
			options: {},
			emptyText: {
				type: String,
				default: '{ leer }'
			},
			required: Boolean,
			firstOption: String,
			readonly: Boolean,
			containerClass: String,
			integerOnly: Boolean,
			decimalOnly: Boolean,
			stayOpen: Boolean,
            objectType: Number
		},
		data() {
			return {
				showing: false,
				id: '',
				currentValue: null,
				mousedownOnMe: false
			};
		},
		validations() {
			return {
				currentValue: {
					required: (value) => {
						if (this.required) {
							if (this.isPrice) {
								return !!value.price;
							} else {
								return !!value;
							}
						} else {
							return true;
						}
					},
					integer: (value) => {
						if (this.isPrice) {
							return integer(value.price);
						}
						else if (this.integerOnly) {
							return integer(value);
						} else if (this.decimalOnly) {
							return decimal(value);
						} else {
							return true;
						}
					}
				}
			};
		},
		created() {
			this.id = 'ile-' + Math.floor(Math.random() * (99999999));
			EventBus.$on('inline-editor-opened', this.checkIfClose);
			document.addEventListener('mousedown', this.documentMouseDown);
			document.addEventListener('click', this.documentClicked);
		},
		beforeDestroy() {
			EventBus.$off('inline-editor-opened', this.checkIfClose);
			document.addEventListener('mousedown', this.documentMouseDown);
			document.removeEventListener('click', this.documentClicked);
		},
		computed: {
			isText() {
				return this.type.toLowerCase() === 'text';
			},
			isTextArea() {
				return this.type.toLowerCase() === 'textarea';
			},
			isTypeahead() {
				return this.type.toLowerCase() === 'text-typeahead';
			},
			isPrice() {
				return this.type.toLowerCase() === 'price';
			},
			isCustom() {
				return this.type.toLowerCase() === 'custom';
			},
			isSelect() {
				return this.type.toLowerCase() === 'select';
			},
			optionsNormalized() {
				var options = this.options || {};
				switch (this.type.toLowerCase()) {
					case 'text-typeahead':
						return _.assign({
							type: 0,
							mode: 'String',
						}, options);
					default:
						return options;
				}
			},
			isEmpty() {
				switch (this.type.toLowerCase()) {
					case 'price':
						return !this.value.price;
					default:
						return !this.text;
				}
			},
			errorMessage() {
				switch (this.type.toLowerCase()) {
					case 'select':
						return 'Bitte wählen Sie etwas aus';
					case 'price':
						if (!this.$v.currentValue.integer)
							return 'Preis muss eine gültige Zahl sein, ohne Kommastellen';
						return 'Bitte geben Sie einen Preis an';
					default:
						if ((this.integerOnly || this.decimalOnly) && !this.$v.currentValue.integer)
							return 'Der Wert muss eine gültige Zahl sein' + (this.integerOnly ? ', ohne Kommastellen' : '');
						return 'Bitte geben Sie einen Wert ein';
				}
			}
		},
		methods: {
			ensureContainerExists() {
				var container = document.getElementById('iec-container');
				if (!container) {
					container = document.createElement('DIV');
					container.id = 'iec-container';
					document.body.appendChild(container);
				}
				container.className = ('iec-' + this.type + ' ' + (this.containerClass || '')).trim();
			},
			textClicked() {
				if (this.readonly) { return; }
				if (this.isCustom) {
					this.$emit('show-custom');
				} else {
					this.$emit('editing');
					this.ensureContainerExists();
					this.$v.$reset();
					this.$refs.popover.$emit('open');
					this.showing = true;
					EventBus.$emit('inline-editor-opened', this.id);
					this.focusInput();
				}
			},
			documentClicked(e) {
				const target = e.toElement || e.target;
				if (!this.mousedownOnMe && !this.stayOpen && target && !target.closest('.inline-editor') && !target.closest('#iec-container')) {
					this.close();
				}
				this.mousedownOnMe = false;
			},
			documentMouseDown(e) {
				const target = e.toElement || e.target;
				if (!this.stayOpen && (target.closest('.inline-editor') || target.closest('#iec-container'))) {
					this.mousedownOnMe = true;
				}
			},
			checkIfClose(id) {
				if (this.showing && this.id !== id) {
					this.close();
				}
			},
			close() {
				this.$refs.popover && this.$refs.popover.$emit('close');
				this.showing = false;
				this.currentValue = this.value;
			},
			submit(ev) {
				ev.preventDefault();
				this.$v.$touch();
				if (this.$v.$invalid) {
					this.focusInput();
					return;
				}

				if (this.isPrice) {
					this.currentValue.price = (this.currentValue.price && parseInt(this.currentValue.price)) || null;
				} else if (this.integerOnly && this.currentValue) {
					this.currentValue = parseInt(this.currentValue);
				} else if (this.decimalOnly && this.currentValue) {
					this.currentValue = parseFloat(this.currentValue);
				}
				this.$emit('updated', this.currentValue);
				this.close();
			},
			focusInput() {
				this.$nextTick(() => {
					this.$nextTick(() => {
						if (this.isTypeahead || this.isPrice) {
							this.$refs[this.type].$emit('setFocus');
						} else {
							this.$refs[this.type].focus && this.$refs[this.type].focus();
							this.$refs[this.type].select && this.$refs[this.type].select();
						}
					});
				});
			},
			priceChanged(value) {
				this.currentValue.currency = value.currency;
				this.currentValue.price = value.price;
			}

		},
		watch: {
			value: {
				handler(value) {
					if (this.isPrice) {
						if ((!value || JSON.stringify(value)) !== (!this.currentValue || JSON.stringify(this.currentValue))) {
							this.currentValue = (value && _.clone(value)) || { currency: '', price: null };
						}
					} else if (value !== this.currentValue) {
						this.currentValue = value;
					}
				},
				immediate: true,
				deep: true
			}
		}
	};
</script>
