export const integer = (value, canBeNegative) => {
	if (typeof value === 'undefined' || value === null || value === '') {
		return true;
	}
	if (canBeNegative)
		return /^[-]{0,1}[0-9]*$/.test(value);
	else
		return /^[0-9]*$/.test(value);
};

export const decimal = (value, canBeNegative) => {
	if (typeof value === 'undefined' || value === null || value === '') {
		return true;
	}
	if (canBeNegative)
		return /^[-]{0,1}[\d]+(\.[\d]+){0,1}$/i.test(value);
	else
		return /^[\d]+(\.[\d]+){0,1}$/i.test(value);
};
