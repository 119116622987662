import { filterBaseModel } from '@/_models';

export class caravanFilterModel extends filterBaseModel {
	constructor(data) {
		super(data);
		this.update(data || {});
	}

	update(value) {
		super.update(value);

		this.brandId = value.brandId || null;
		this.typeId = value.typeId || null;
		this.name = value.name || '';
	}
}
