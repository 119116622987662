import { validationModel } from '@/_models';
import { isArray } from 'lodash';

export const validationMixin = {
	data: () => ({
		_validators: {},
		_validationModels: [],
		_validatorsReady: false,
		showGoodState: true
	}),
	validations() {
		if (!this.rules) {
			console.warn('"rules" must be defined as an object or a function');
			return {};
		}

		const addValidations = function (self, rules, validations, models, propName, parentPropName) {
			validations[propName] = {};
			if (isArray(rules)) {
				let model = new validationModel(self.showGoodState);
				let $each = null;
				rules.forEach((r, index) => {
					if (r.val) {
						validations[propName]['val_' + index] = r.val;
						model.addMessage('val_' + index, r.msg);
					} else if (r.$each) {
						$each = r.$each;
					}
				});
				models[parentPropName + propName] = model;
				if ($each) {
					addValidations(self, $each, validations[propName], models, '$each', parentPropName + propName + '.');
				}
			} else {
				for (const prop in rules) {
					addValidations(self, rules[prop], validations[propName], models, prop, parentPropName + propName + '.');
				}
			}
		};

		let validations = {};
		let models = {};
		for (const prop in this.rules) {
			addValidations(this, this.rules[prop], validations, models, prop, '');
		}
		this._validationModels = models;
		return validations;
	},
	watch: {
		$v: {
			handler() {
				if (!this.$v) { return; }

				const updateMessages = function (validator, models, parentProp) {
					for (const prop in validator) {
						if (!prop.startsWith('$') && !prop.startsWith('val_')) {
							if (models[parentProp + prop]) {
								models[parentProp + prop].updateState(validator[prop]);
							}
							updateMessages(validator[prop], models, parentProp + prop + '.');
						} else if (prop === '$each') {
							// go through all the items
							for (const index in validator.$each) {
								if (!index.startsWith('$'))
									updateMessages(validator.$each[index], models, parentProp + '$each.');
							}
						}
					}
				};

				updateMessages(this.$v, this._validationModels, '');
			},
			deep: true,
			immediate: true
		}
	}
};
