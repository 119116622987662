import { discardChangesMixin, loadingMixin } from '@/_mixins';
import { currencies } from '@/_helpers';

export const formMixin = {
	mixins: [discardChangesMixin, loadingMixin],
	props: {
		id: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			ready: false,
			submitting: false,
			errors: [],
			mainLabel: '',
			currencies: currencies,
			notifyUnsavedChanges: true,
			original: '',
			originalHasBeenSet: false,
			saveAdditionalData: null,
			isCopy: false,
			copyRouteName: ''
		};
	},
	validations: {},
	computed: {
		titleText() {
			return (this.computedMainLabel + ' ' + (this.isCopy ? 'kopieren' : !this.isNew ? 'bearbeiten' : 'erstellen')).trim();
		},
		saveText() {
			return this.isCopy ? 'Kopieren' : !this.isNew ? 'Speichern' : 'Erstellen';
		},
		saveIcon() {
			return this.isCopy ? 'copy' : !this.isNew ? 'save' : 'plus';
		},
		saveVariant() {
			return this.isCopy ? 'warning' : !this.isNew ? 'primary' : 'success';
		},
		computedMainLabel() {
			return this.mainLabel;
		},
		isNew() {
			return !this.id;
		}
	},
	methods: {
		submit(e) {
			e.preventDefault();

			if (!this.canSubmit()) {
				return;
			}
			this.submitData();
		},
		canSubmit(additionalData) {
			if (this.submitting) { return false; }

			this.errors = [];
			if (!this.isValid()) {
				return false;
			} else {
				this.loading = true;
				this.submitting = true;
				this.saveAdditionalData = additionalData;
				return true;
			}
		},
		resetForm(loadData) {
			this.submitting = false;
			this.$v.$reset();
			if (loadData) {
				this.loading = true;
				this.loadData();
			}
		},
		isValid() {
			this.$v.$touch();
			return !this.$v.$invalid;
        },
		submitData() {
			console.warn('submitData was called, but should be overwritten in the component using this mixin. Called when the form is submitted');
		},
		loadData() {
			console.warn('loadData was called, but should be overwritten in the component using this mixin. Called when the route is changed');
		},
		focus(refKey) {
			this.$nextTick(() => {
				this.$nextTick(() => {
					var target = this.$refs[refKey];
					target && target.setFocus && target.setFocus();
					target && target.focus && target.focus();
					target && target.select && target.select();
				});
			});
		},
		hasUnsavedChanges() {
			if (this.notifyUnsavedChanges) {
				if (window.isDevelopment) {
					console.warn('hasUnsavedChanges was called, but should be overwritten in the component using this mixin. Called when the component needs no know if it is dirty of not');
					return true;
				} else {
					return false;
				}
			}
			return this.originalHasBeenSet;
		},
		stringfyReplacer(key, value) {
			return value;
		},
		setOriginal(o) {
			this.originalHasBeenSet = true;
			this.original = JSON.stringify(o, this.stringfyReplacer);
		},
		sameAsOriginal(o) {
			return !this.originalHasBeenSet || this.original === JSON.stringify(o, this.stringfyReplacer);
		}
	},
	watch: {
		'$route': {
			handler() {
				this.loading = true;
				this.ready = false;
				this.errors = [];
				this.isCopy = !!(this.copyRouteName && this.$route.name === this.copyRouteName);

				this.loadData();
			},
			immediate: true
		},
		errors: {
			handler() {
				this.submitting = false;
			},
			immediate: true
		},
		id: {
			handler(value) {
				value && this.updateLastEdited && this.updateLastEdited(value);
			},
			immediate: true
		}
	}
};
