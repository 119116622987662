<template>
	<i :class="['fa', 'fa-' + name, noText ? '' : 'pad-right', textLeft ? 'pad-left' : '']"></i>
</template>
<script>
	export default {
		name: 'icon',
		props: {
			name: {
				required: true,
				type: String
			},
			noText: Boolean,
			textLeft: Boolean
		}
	};
</script>
