<template>
	<b-input-group>
		<b-form-select slot="prepend"
					   :id="'select-' + id"
					   :value="currency"
					   :disabled="!!restrictCurrency || readonly || disableCurrency"
					   @change="currencyChanged"
					   :state="currencyValidator && currencyValidator.$dirty ? !currencyValidator.$error : null"
					   :options="currencies"
					   :tabindex="currencySkipTab ? -1 : null" />
		<b-form-input :id="'input-' + id"
					  ref="input"
					  type="text"
					  :value="price"
					  :class="[inputCols ? 'col-' + inputCols : '']"
					  @input="priceChanged"
					  :disabled="readonly"
					  :state="priceState"
					  :aria-describedby="'input-' + id + '-feedback'"
					  :placeholder="placeholder" />
		<b-form-invalid-feedback :id="'input-' + id + '-feedback'" v-if="errorMessage">
			<slot name="error">{{ errorMessage }}</slot>
		</b-form-invalid-feedback>
	</b-input-group>
</template>
<script>
	import { currencies } from '@/_helpers';

	export default {
		name: 'price',
		props: {
			currency: {
				required: true,
				type: String,
				validator: function (value) {
					return currencies.indexOf(value) !== -1
				}
			},
			price: {
				required: true
			},
			id: {
				required: true,
				type: String
			},
			inputCols: {
				type: [String, Number]
			},
			validation: {
				type: Object
			},
			errorMessage: {
				type: String
			},
			placeholder: {
				type: String
			},
			readonly: {
				type: Boolean,
				default: false
			},
			currencyValidator: Object,
			priceValidator: Object,
			currencySkipTab: Boolean,
			disableCurrency: Boolean,
			restrictCurrency: String
		},
		created() {
			this.$on('setFocus', this.setFocus);
		},
		beforeDestroy() {
			this.$off('setFocus', this.setFocus);
		},
		data() {
			return {
				currencies: currencies
			};
		},
		computed: {
			value() {
				return {
					currency: this.currency,
					price: this.price
				};
			},
			priceState() {
				if (this.validation && this.validation.$dirty) {
					return !this.validation.$error;
				} else if (this.priceValidator && this.priceValidator.$dirty) {
					return !this.priceValidator.$error;
				}
				return null;
			}
		},
		methods: {
			currencyChanged(value) {
				this.update('currency', value);
			},
			priceChanged(value) {
				this.update('price', value);
			},
			update(key, value) {
				this.$emit('changed', { ...this.value, [key]: value })
			},
			setFocus() {
				this.$nextTick(() => {
					this.$nextTick(() => {
						this.$refs['input'] && this.$refs['input'].focus && this.$refs['input'].focus();
						this.$refs['input'] && this.$refs['input'].select && this.$refs['input'].select();
					});
				});
			}
		}
	};
</script>
