import Vue from 'vue';
import { TextTypes } from '@/_helpers';
import { textModel } from '@/_models';

export class packageModel {
	constructor(data) {
		this.update(data || {});
	}

	update(value) {
		this.id = value.id || 0;
		this.caravanId = value.caravanId;
		if (this.text) {
			this.text.update(value.text);
		} else {
			this.text = new textModel(value.text || {
				type: TextTypes.Package
			});
		}
		this.additionalText = value.additionalText || null;
		this.currency = value.currency || Vue.$s.currency;
		this.price = value.price || null;
		this.weight = value.weight || null;
		this.options = value.options || [];
		if (!value.includedItems || !value.includedItems.length) {
			value.includedItems = [''];
		}
		this.includedItems = value.includedItems.map(i => {
			return {
				text: i,
				deleted: false
			};
		});
	}
}
