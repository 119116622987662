export class textModel {
	constructor(data) {
		this.update(data || {});
	}

	update(value) {
		this.id = value.id || 0;
		this.type = value.type || 0;
		if (!this.type) {
			console.warn('Text must have a type!');
		}
		this.value = value.value || '';
		this.locked = !!value.locked;
	}
}
