export const typeBaseMixin = {
	data() {
		return {
			lastEditTypeKey: ''
		};
	},
	computed: {
		lastEditedId() {
			return (this.lastEditTypeKey && this.$store.getters['lastEdited/getValue'](this.lastEditTypeKey)) || null;
		}
	},
	methods: {
		updateLastEdited(id) {
			if (this.lastEditTypeKey) {
				this.$store.commit('lastEdited/update', {
					key: this.lastEditTypeKey,
					value: id
				});
			}
		}
	}
};
