import { typeBaseMixin } from '@/_mixins';

export const clientTypeMixin = {
	mixins: [typeBaseMixin],
	data() {
		return {
			lastEditTypeKey: 'clients'
		};
	}
};
