import { filterBaseModel } from '@/_models';

export class offerFilterModel extends filterBaseModel {
	constructor(data) {
		super(data);
		this.update(data || {});
	}

	update(value) {
		super.update(value);

		this.client = value.client || '';
		this.model = value.model || '';
		this.status = value.status;
		this.typeId = value.typeId || null;
	}
}
