<template>
	<span class="type-ahead" ref="container" :class="{ 'locked': isLocked }">
		<b-input :value="valueAsString" :placeholder="placeholder" ref="input" :disabled="disabled" :state="validator && validator.$dirty ? !validator.$error : null" />
		<a v-if="allowLocking" class="text-locked" :title="isLocked ? 'Beim anpassen vom Text werden ALLE Instanzen angepasst' : 'Text wird nicht alle Instanzen ersetzen'" @click="toggleLock">
			<icon :name="isLocked ? 'link' : 'unlink'" />
		</a>
		<typeahead v-if="!disabled" v-model="currentValue" :target="target" :async-function="search" item-key="value" />
		<b-form-invalid-feedback v-if="errorMessage">{{ errorMessage }}</b-form-invalid-feedback>
	</span>
</template>
<script>
	import Vue from 'vue';
	import { Typeahead } from 'uiv'
	import _ from 'lodash';

	export default {
		name: 'text-input-typeahead',
		model: {
			prop: 'value',
			event: 'updated'
		},
		props: {
			type: {
				type: Number,
				required: true
			},
			value: {
				type: [Object, String],
				required: true
			},
			mode: {
				type: String,
				default: 'String',
				validator: function (value) {
					// The value must match one of these strings
					return ['string', 'object'].indexOf(value.toLowerCase()) !== -1
				}
			},
			placeholder: String,
			disabled: Boolean,
			focus: Boolean,
			validator: Object,
			errorMessage: String,
			allowLocking: Boolean,
			objectType: Number
		},
		data() {
			return {
				currentValue: '',
				modeIsString: true,
				target: null,
				lastValue: null,
				lastQuery: null
			};
		},
		created() {
			this.$on('setFocus', this.setFocus);
		},
		mounted() {
			this.target = this.$refs['input'];
			this.$refs['container'].addEventListener('keydown', this.preventEnter, true);
			this.focus && this.setFocus();
		},
		computed: {
			valueAsString() {
				if (this.currentValue && !_.isString(this.currentValue)) {
					//console.log('valueAsString - not a string', this.currentValue.value)
					return this.currentValue.value;
				}
				//console.log('valueAsString - currentValue', this.currentValue);
				return this.currentValue;
			},
			idIsSet() {
				return !this.modeIsString && !!this.currentValue;
			},
			isLocked() {
				return this.allowLocking && this.idIsSet && this.currentValue.locked;
			}
		},
		methods: {
			search(query, done) {
				//console.log('search', query);
				if (query === this.lastQuery) { return; }
				this.lastQuery = query;
                Vue.webApi.post('text', 'find', { type: this.type, value: query, objectType: this.objectType })
					.then(response => {
						//console.log(response);
						if (response.success && response.data.query === this.lastQuery) {
							done(response.data.items || []);
						}
					});
			},
			preventEnter(ev) {
				if (ev.keyCode === 13 && this.$refs['container'] && this.$refs['container'].querySelectorAll('.open').length) {
					ev.preventDefault();
					return false;
				}
			},
			setFocus() {
				this.$refs['input'] && this.$refs['input'].focus();
				this.$refs['input'] && this.$refs['input'].select();
			},
			toggleLock() {
				var result = _.clone(this.lastValue);
				result.locked = !this.isLocked;
				this.$emit('updated', result);
			}
		},
		beforeDestroy() {
			this.$refs['container'].removeEventListener('keydown', this.preventEnter);
			this.$off('setFocus', this.setFocus);
		},
		watch: {
			mode: {
				handler(value) {
					this.modeIsString = (value || '').toLowerCase() === 'string';
				},
				immediate: true
			},
			value: {
				handler(value) {
					if (this.modeIsString || _.isString(value)) {
						//console.log('value - isString', value, this.type, this);
						this.currentValue = {
							id: 0,
							type: this.type,
							locked: false,
							value: _.isString(value) ? value : ((value && value.value) || '')
						};
					} else {
						//console.log('value - OBJ', value, value.type);
						this.currentValue = value;
					}
					this.lastValue = _.clone(this.currentValue);
					//console.log('value - cloned', JSON.stringify(this.lastValue));
				},
				immediate: true
			},
			currentValue(value) {
				//console.log('currentValue - CHECK', value);
				if (this.modeIsString) {
					if (value && !_.isString(value)) {
						value = value.value;
					}
					//console.log('currentValue - STRING', this.value, value, this.value !== value);
					if (this.value !== value) {
						//console.log('currentValue - EMIT STRING', value);
						this.$emit('updated', value.value || value || '');
					}
				} else {
					//console.log('currentValue - OBJ', value,_.isString(value), JSON.stringify(this.lastValue));
					var result = _.clone(this.lastValue);

					if (_.isString(value)) {
						result.value = value;
					} else {
						result.id = value.id || result.id;
						result.value = value.value || result.value;
						result.locked = value.locked;
					}
					if (JSON.stringify(result) !== JSON.stringify(this.lastValue)) {
						//console.log('currentValue - EMIT UPDATE', value, JSON.stringify(result), JSON.stringify(this.lastValue));
						this.$emit('updated', result);
					}
				}
			},
			focus(value) {
				value && this.setFocus();
			}
		},
		components: {
			Typeahead
		}
	};
</script>
