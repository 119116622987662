import Vue from 'vue';

const state = {
	types: []
};

const mutations = {
	update(state, data) {
		Vue.set(state.types, data.key, data.value);
	}
};

const getters = {
	getValue: (state) => (key) => {
		return state.types[key] || null;
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	getters
};
