<template>
	<div>
		<div class="row" v-for="item in items">
			<label class="col-form-label col-3">{{ item.label }}</label>
			<label class="col-form-label col-9 font-weight-bold" v-html="$options.filters.toBr(item.value)"></label>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'delete-properties',
		props: {
			items: {
				required: true,
				type: Array
			}
		},
	};
</script>
