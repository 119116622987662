<template>
	<div class="row no-gutters">
		<div class="col-6">
			<b-input-group :class="{ 'is-invalid': invalid }">
				<b-form-input placeholder="TT" v-model="$v.day.$model" :state="$v.day.$dirty ? !$v.day.$error && !$v.validDate.$invalid : null" />
				<b-form-input placeholder="MM" v-model="$v.month.$model" :state="$v.month.$dirty ? !$v.month.$error && !$v.validDate.$invalid : null" />
				<b-form-input placeholder="YYYY" v-model="$v.year.$model" :state="$v.year.$dirty ? !$v.year.$error && !$v.validDate.$invalid : null" />
			</b-input-group>
			<b-form-invalid-feedback>
				<div v-if="oneInput && !$v.day.required">Tag fehlt!</div>
				<div v-if="$v.day.required && (!$v.day.integer || !$v.day.between)">Tag ist nicht gültig!</div>
				<div v-if="oneInput && !$v.month.required">Monat fehlt!</div>
				<div v-if="$v.month.required && (!$v.month.integer || !$v.month.between)">Monat ist nicht gültig!</div>
				<div v-if="oneInput && !$v.year.required">Jahr fehlt!</div>
				<div v-if="$v.year.required && (!$v.year.integer || !$v.year.between)">Jahr ist nicht gültig!</div>
				<div v-if="(makeDirty || allDirty) && !$v.validDate.required">Datum ist nicht gültig!</div>
			</b-form-invalid-feedback>
		</div>
	</div>
</template>
<script>
	import moment from 'moment';
	import { required, between } from 'vuelidate/lib/validators';
	import { integer } from '@/_validators';

	export default {
		name: 'dob',
		model: {
			prop: 'value',
			event: 'update'
		},
		props: {
			value: [Date, String],
			required: Boolean,
			makeDirty: Boolean
		},
		data() {
			return {
				day: null,
				month: null,
				year: null,
				validDate: null,
				lastEmitted: null
			};
		},
		validations: {
			day: {
				required: (value, self) => {
					if (self.allDirty && (self.month || self.year)) {
						return required(value);
					}
					return !self.required || required(value);
				},
				integer,
				between: between(1, 31)
			},
			month: {
				required: (value, self) => {
					if (self.allDirty && (self.day || self.year)) {
						return required(value);
					}
					return !self.required || required(value);
				},
				integer,
				between: between(1, 12)
			},
			year: {
				required: (value, self) => {
					if (self.allDirty && (self.month || self.day)) {
						return required(value);
					}
					return !self.required || required(value);
				},
				integer,
				between: between(moment().year() - 100, moment().year())
			},
			validDate: {
				required: (value, self) => {
					if (self.$v.day.$error || self.$v.month.$error || self.$v.year.$error) {
						return true;
					} else if (self.day && self.month && self.year && !value) {
						return false;
					} else {
						return !self.required;
					}
				}
			},
			reset() {
				this.$v.$reset;
			}
		},
		computed: {
			oneInput() {
				return this.day || this.month || this.year;
			},
			allDirty() {
				return this.$v.day.$dirty && this.$v.month.$dirty && this.$v.year.$dirty;
			},
			invalid() {
				return this.invalidInput || this.$v.validDate.$invalid;
			},
			$invalid() {
				try {
					return !!(this.$v && this.$v.$invalid);
				} catch (e) {
					return false;
				}
			},
			invalidInput() {
				return (this.$v.day.$dirty && !!this.$v.day.$error) ||
					(this.$v.month.$dirty && !!this.$v.month.$error) ||
					(this.$v.year.$dirty && !!this.$v.year.$error)
					;
			},
			date() {
				var date = this.year + '-' + this.month + '-' + this.day;

				if (this.invalidInput || !this.day || !this.month || !this.year)
					return null;

				var mDate = moment.utc(date, 'YYYY-M-D');
				if (mDate.isValid())
					return mDate.utc().toDate();
				else
					return null;
			}
		},
		watch: {
			value: {
				handler(value) {
					if (value) {
						var mDate = moment(value);
						if (mDate.isValid()) {
							this.day = mDate.date();
							this.month = mDate.month() + 1;
							this.year = mDate.year();
							return;
						}
					}
				},
				immediate: true
			},
			date: {
				handler(value) {
					this.validDate = value;
					if (value === this.lastEmitted || moment(this.lastEmitted).isSame(value)) {
						return;
					}
					this.lastEmitted = value;
					this.$emit('update', value);
				},
				immediate: true
			},
			makeDirty: {
				handler(value) {
					if (value) {
						this.$v.$touch();
					}
				},
				immediate: true
			},
			$invalid: {
				handler(value) {
					this.$emit('isInvalid', !!value);
				},
				immediate: true
			}
		}
	};
</script>
