export const filesize = {
	calc,
	units: ['TB', 'GB', 'MB', 'KB', 'b']
};

function calc(size, filesizeBase) {
	if (!size) return { total: '', unit: '' };

	var cutoff, i, selectedSize, selectedUnit, unit, _i, _len;

	filesizeBase = filesizeBase || 1000;
	selectedSize = selectedUnit = null;
	for (i = _i = 0, _len = this.units.length; _i < _len; i = ++_i) {
		unit = this.units[i];
		cutoff = Math.pow(filesizeBase, 4 - i) / 10;
		if (size >= cutoff) {
			selectedSize = size / Math.pow(filesizeBase, 4 - i);
			selectedUnit = unit;
			break;
		}
	}
	selectedSize = Math.round(10 * selectedSize) / 10;
	return {
		total: selectedSize,
		unit: selectedUnit
	};

}
