import { caravanBasicDataModel, caravanCategoryModel, extraItemModel, discountModel } from '@/_models';
import _ from 'lodash';

export class configurableItemModel {
	constructor(data) {
		this.update(data || {});
	}

	update(value) {
		_.merge(this, value);
		this.id = value.id || 0;
		this.isStockItem = value.isStockItem || false;
		this.currency = value.currency || 'EUR';
		this.basicData = (value.basicData || []).map(b => new caravanBasicDataModel(b));
		this.categories = (value.categories || []).map(c => new caravanCategoryModel(c, this.id));
		this.extraItems = (value.extraItems || []).map(i => new extraItemModel(i, this.id));
		this.discount = new discountModel(value.discount);
	}
}
