import { basicDataSelectionModel, mediaModel } from '@/_models';
import Vue from 'vue';
import { merge } from 'lodash';

export class caravanModel {
	constructor(data, basicDatas) {
		this.update(data || {}, basicDatas || []);
	}

	update(value, basicDatas) {
		merge(this, value);
		this.id = value.id || 0;
		this.brandId = value.brandId || null;
		this.typeId = value.typeId || null;
		this.brand = value.brand;
		this.name = value.name;
		this.layoutId = value.layoutId || '';
		this.online = value.online;
		this.currency = value.currency || Vue.$s.currency;
		this.layout = new mediaModel(value.layout);
		this.value = this.modelName;
		this.lengthTotal = value.lengthTotal;
		this.lengthUsage = value.lengthUsage;
		this.lengthBuild = value.lengthBuild;
		this.width = value.width;
		this.weightTotal = value.weightTotal;
		this.awningMessurement = value.awningMessurement;

		this.basicDatas = (value.basicDatas || []).map(i => new basicDataSelectionModel(i, basicDatas));
	}

	get modelName() {
		return ((this.brand || '') + ' ' + (this.name || '')).trim();
	}

	addBasicData(basicData) {
		this.basicDatas.push(new basicDataSelectionModel(basicData));
	}

	toPayload() {
		let order = 1;
		return {
			id: this.id,
			brandId: this.brandId,
			brand: this.brand,
			name: this.name,
			layoutId: this.layoutId,
			online: this.online,
			currency: this.currency,
			layout: this.layout,
			value: this.value,
			basicDatas: this.basicDatas.filter(i => i.required || (i.value && !i.deleted)).map(i => ({
				id: i.id,
				value: i.value,
				order: order++
			})),
		};
    }
}
