import Vue from 'vue';
import { formMixin } from '@/_mixins';
import { TextTypes } from '@/_helpers';
import { configurableItemModel, caravanCategoryModel, totalModel } from '@/_models';
import _ from 'lodash';

export const configMixin = {
	mixins: [formMixin],
	props: {
		id: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			item: new configurableItemModel(),
			firstCategory: new caravanCategoryModel(),
			packageCategory: null,
			categories: [],
			totalOptions: new totalModel(),
			totalExtraItems: new totalModel(),
			total: new totalModel(),
			extraItemsCategory: new caravanCategoryModel({
				id: -1,
				readonly: true,
				text: {
					type: TextTypes.NotRelevant,
					value: 'Zusatz-Einbauten'
				}
			}),
			loadBasicData: false,
			calculating: false,
			displayCurrency: null
		};
	},
	computed: {
		allCategories() {
			let result = this.categories.slice(0);
			this.packageCategory && result.splice(0, 0, this.packageCategory);
			this.firstCategory && result.splice(0, 0, this.firstCategory);
			return result;
		},
		selectedOptions() {
			let options = [];
			this.allCategories && this.allCategories.length && this.allCategories
				.filter(c => !c.deleted)
				.forEach(c => {
					options = _.concat(options, this.getSelectedOptions(c));
				});
			return options;
		},
		selectedOptionsWithPrice() {
			return this.selectedOptions.filter(o => o.hasPrice);
		},
		selectedExtraItems() {
			return (this.extraItemsCategory.extraItems || []).filter(i => i.selected && !i.deleted);
		},
		packageOptions() {
			let optionIds = [];
			this.packageCategory && this.packageCategory.options.forEach(o => {
				if (o.selected) {
					optionIds = _.concat(optionIds, o.excludeOptions);
				}
			});
			return optionIds;
		}
	},
	watch: {
		selectedOptions: {
			handler() {
				this.totalOptions.recalculate(this.selectedOptions);
			},
			immerdiate: true,
			deep: true
		},
		selectedExtraItems: {
			handler() {
				this.totalExtraItems.recalculate(this.selectedExtraItems);
			},
			immerdiate: true,
			deep: true
		},
		'$s.version': {
			handler() {
				this.totalOptions.recalculate(this.selectedOptions);
				this.totalExtraItems.recalculate(this.selectedExtraItems);
			},
			immerdiate: true
		},
		totalOptions: {
			handler() {
				this.total.subTotal = this.totalOptions.total + this.totalExtraItems.total;
				this.total.weight = this.totalOptions.weight + this.totalExtraItems.weight;
			},
			immediate: true,
			deep: true
		},
		totalExtraItems: {
			handler() {
				this.total.subTotal = this.totalOptions.total + this.totalExtraItems.total;
				this.total.weight = this.totalOptions.weight + this.totalExtraItems.weight;
			},
			immediate: true,
			deep: true
		},
		displayCurrency: {
			handler() {
				this.applyDisplayCurrency();
			},
			immediate: true
		},
		'$s.exchangeRate': {
			handler() {
				this.item.exchangeRate = this.$s.exchangeRate;
			},
			immediate: true
		}
	},
	methods: {
		getSelectedOptions(item) {
			var result = [];
			item.options && item.options.length && item.options.forEach(o => {
				if (o.selected && !o.deleted && this.packageOptions.indexOf(o.id) === -1) {
					result.push(o);
					var oOptions = this.getSelectedOptions(o);
					if (oOptions.length) {
						result = _.concat(result, oOptions);
					}
				}
			});
			return result;
		},
		loadConfig(callback) {
			Vue.webApi.get('stocks', 'get/' + this.id, null, 'config=true&basicData=' + this.loadBasicData)
				.then(
					response => {
						if (response.success) {
							// set the config item
							this.item.update(response.data);

							callback(response.data);
						} else {
							this.$toastr('error', response.messages[0]);
							this.$router.push({ name: 'stocks' });
						}
					}
				);
		},
		loadData() {
			this.loadConfig(data => {
				this.loadCategories(data.categories);

				// discounts
				this.totalOptions = new totalModel({
					subTotal: 0,
					isPercentage: true,
					discount: data.discountOptions,
					additionalItems: data.additionalOptions
				});
				this.totalExtraItems = new totalModel({
					subTotal: 0,
					isPercentage: true,
					discount: data.discountExtraItems,
					displayCurrency: 'CHF',
					additionalItems: data.additionalExtraItems
				});
				this.total = new totalModel({
					subTotal: 0,
					discount: data.discountOverall,
					additionalItems: data.additionalTotal
				});

				// extra items
				this.extraItemsCategory.caravanId = this.item.id;
				this.extraItemsCategory.updateExtraItems(data.extraItems, this.item.id);

				// ok, set the original object
				this.setOriginal(this.itemForServer());
				this.ready = true;
				this.loading = false;
				this.loaded();
			});
		},
		loadCategories(categories) {
			// categories - first and package category should be extracted
			categories = categories.map(c => new caravanCategoryModel(c, this.item.id));
			this.firstCategory = categories[0];
			categories.splice(0, 1);
			this.packageCategory = null;
			if (categories.length && categories[0].isPackage) {
				this.packageCategory = categories[0];
				categories.splice(0, 1);
			}
			this.categories = categories;
		},
		stringfyReplacer(key, value) {
			switch (key.toLowerCase()) {
				case '_displaycurrency':
				case 'displaycurrency':
					return undefined;
			}
			return value;
		},
		itemForServer() {
			var item = _.assign(this.item, {
				categories: this.allCategories,
				discountOptions: this.totalOptions.discount,
				discountExtraItems: this.totalExtraItems.discount,
				discountOverall: this.total.discount,
				extraItems: this.extraItemsCategory.extraItems,
				currency: this.$s.currency,
				additionalOptions: this.totalOptions.additionalItems.filter(i => !i.deleted),
				additionalExtraItems: this.totalExtraItems.additionalItems.filter(i => !i.deleted),
				additionalTotal: this.total.additionalItems.filter(i => !i.deleted)
			});

			this.ready && this.updateServerItem(item);
			return item;
		},
		applyDisplayCurrency() {
			this.allCategories.forEach(c => {
				c.options.forEach(o => {
					o.displayCurrency = this.displayCurrency;
				});
			});
			this.totalOptions.subTotalCurrency = this.displayCurrency;
			this.totalOptions.displayCurrency = this.item.currencyClient;
			this.totalExtraItems.displayCurrency = 'CHF';
			this.total.displayCurrency = this.item.currencyClient;
		},
		loaded() { },
		updateServerItem(serverItem) {
			return serverItem;
		},
		hasUnsavedChanges() {
			return !this.sameAsOriginal(this.itemForServer());
		}
	}
};
