export const SalutationTypes = {
	NotSet: 0,
	Mr: 1,
	Mrs: 2,
	MrAndMrs: 3,
	Company: 4,

	getText,
	getList
};

function getText(type) {
	switch (type) {
		case SalutationTypes.NotSet:
			return 'Bitte die Anrede wählen';
		case SalutationTypes.Mr:
			return 'Herr';
		case SalutationTypes.Mrs:
			return 'Frau';
		case SalutationTypes.MrAndMrs:
			return 'Herr und Frau';
		case SalutationTypes.Company:
			return 'Firma';
		default:
			console.warn('SalutionType not know');
			return 'Unknown';
	}
}

function getList(addNotSet) {
	var result = [
		SalutationTypes.NotSet,
		SalutationTypes.Mr,
		SalutationTypes.Mrs,
		SalutationTypes.MrAndMrs,
		SalutationTypes.Company
	];
	if (!addNotSet)
		result.splice(0, 1);
	return result.map(t => {
		return {
			value: t,
			text: getText(t)
		};
	});
}
