<template>
	<span></span>
</template>
<script>
	import { requestHeader } from '@/_helpers';
	import Dropzone from 'dropzone';

	export default {
		name: 'dz-processor',
		props: {
			selector: {
				type: String,
				required: true
			},
			url: {
				type: String,
				required: true
			},
			paramName: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				customUploader: null
			};
		},
		mounted() {
			var self = this;

			this.customUploader = new Dropzone(this.selector, {
				url: this.url,
				headers: {
					...requestHeader.authHeader()
				},
				paramName: this.paramName,
				uploadMultiple: false,
				parallelUploads: 1,
				autoProcessQueue: true,
				autoDiscover: false,
				init: function () {
					this.on('thumbnail', (file, dataUrl) => {
						self.$emit('thumbLoaded', file, dataUrl);
					}).on('uploadprogress', function (file, progress, bytesSent) {
						self.$emit('updateProgress', file, progress);
					}).on('success', function (file, response) {
						self.$emit('uploadComplete', file, response);
					}).on('error', function (file, msg) {
						//console.log('error', file, msg);
						self.$emit('error', file, msg);
						//self.processNextFile();
					});
				}
			});
		},
		beforeDestroy() {
			this.customUploader && this.customUploader.destroy && this.customUploader.destroy();
			this.customUploader = null;
		},
		methods: {
			addFile(file) {
				this.customUploader.addFile(file);
			}
			//processNextFile() {

			//}
		}
	};
</script>
