<template>
	<b-form-group :id="id"
				  label-cols="3"
				  :label="label"
				  :state="validation && validation.$dirty ? !validation.$error : null"
				  :label-for="id && 'input-' + id">
		<slot name="input">
			<b-input-group :prepend="prepend" :append="append" :class="{ 'is-invalid': validation && validation.$dirty && !!validation.$error }">
				<b-form-input v-if="isText"
							  :id="id && 'input-' + id"
							  ref="input"
							  :type="type"
							  :value="model"
							  :class="[inputCols ? 'col-' + inputCols : '']"
							  @input="changed"
							  @focus="hasFocus"
							  :disabled="readonly"
							  :state="validation && validation.$dirty ? !validation.$error : null"
							  :aria-describedby="id && 'input-' + id + '-feedback'"
							  :placeholder="placeholder" />
				<b-form-textarea v-if="isTextarea"
								 :id="id && 'input-' + id"
								 ref="inputArea"
								 :type="type"
								 :value="model"
								 :class="[inputCols ? 'col-' + inputCols : '']"
								 @input="changed"
								 @focus="hasFocus"
								 :disabled="readonly"
								 :rows="rows"
								 :state="validation && validation.$dirty ? !validation.$error : null"
								 :aria-describedby="id && 'input-' + id + '-feedback'"
								 :placeholder="placeholder" />
			</b-input-group>
			<b-form-invalid-feedback :id="id && 'input-' + id + '-feedback'">
				<slot name="error">{{ errorMessage }}</slot>
			</b-form-invalid-feedback>
		</slot>
		<slot name="help-text"></slot>
	</b-form-group>
</template>
<script>
	export default {
		name: 'form-group',
		model: {
			prop: 'model',
			event: 'model'
		},
		props: {
			id: String,
			label: String,
			placeholder: String,
			model: {
				required: false
			},
			validation: Object,
			errorMessage: String,
			focus: Boolean,
			readonly: Boolean,
			inputCols: [String, Number],
			prepend: String,
			append: String,
			type: {
				type: String,
				default: 'text',
				validator: function (value) {
					// The value must match one of these strings
					return ['text', 'password', 'email', 'textarea'].indexOf(value.toLowerCase()) !== -1
				}
			},
			rows: {}
		},
		created() {
			this.$on('setFocus', this.setFocus);
		},
		beforeDestroy() {
			this.$off('setFocus', this.setFocus);
		},
		computed: {
			isText() {
				return ['text', 'password', 'email'].indexOf(this.type.toLowerCase()) !== -1;
			},
			isTextarea() {
				return this.type.toLowerCase() === 'textarea';
			}
		},
		mounted() {
			if (this.focus) {
				this.$nextTick(() => { // wait till everything (like dialog animations) are done
					this.$nextTick(() => {
						setTimeout(() => {
							if (this.isText) {
								this.$refs['input'].focus();
								this.$refs['input'].select();
							} else {
								this.$refs['inputArea'].focus();
								this.$refs['inputArea'].select();
							}
						}, 150);
					});
				});
			}
		},
		methods: {
			changed(value) {
				this.$emit('model', value)
			},
			hasFocus() {
				this.$emit('hasFocus');
			},
			setFocus() {
				this.$nextTick(() => {
					this.$nextTick(() => {
						var target = this.isTextarea ? 'inputArea' : 'input';
						this.$refs[target] && this.$refs[target].focus && this.$refs[target].focus();
						this.$refs[target] && this.$refs[target].select && this.$refs[target].select();
					});
				});
			}
		}
	};
</script>
