import { filterBaseModel } from '@/_models';

export class textFilterModel extends filterBaseModel {
	constructor(data) {
		super(data);
		this.update(data || {});
	}

	update(value) {
		super.update(value);

		this.typeId = value.typeId || 1;
		this.value = value.value || '';
	}
}
