import { authenticationService } from '@/_services';

export const requestHeader = {
	authHeader() {
		const authToken = this.authToken();
		const authHeader = authToken ? { 'Authorization': authToken } : {};

		return {
			...authHeader
		};
	},
	authToken() {
		const currentUser = authenticationService.currentUserValue || {};
		return currentUser.token ? 'Bearer ' + currentUser.token : null;
	}
};
