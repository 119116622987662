<template>
	<div class="media-area">
		<div class="upload-preview" :class="{ 'ul-processing': uploading, 'ul-completed': !uploading, 'no-image': !hasImage }">
			<div class="ul-image">
				<div class="ul-image-container" :style="{ 'background-image': backgroundImage }"></div>
			</div>
			<div class="ul-details">
				<div class="ul-size"><strong>{{ size.total }}</strong> {{ size.unit }}</div>
				<div class="ul-name">{{ file.name }}</div>
			</div>
			<div class="ul-progress">
				<div class="ul-upload" :style="{ width: progress + '%' }"></div>
			</div>
		</div>
		<button ref="manualUpload" type="button" class="btn btn-success btn-block"><icon name="upload" />{{ uploadText }}</button>
		<div class="help-block" v-if="uploadHelpText">{{ uploadHelpText }}</div>

		<dz-processor v-if="id"
					  ref="processor"
					  :selector="'#' + id + ' .fileUploader'"
					  :url="uploadUrl"
					  :paramName="uploadParameter"
					  @thumbLoaded="thumbLoaded"
					  @updateProgress="updateProgress"
					  @uploadComplete="uploadComplete"
					  @error="error" />
	</div>
</template>
<script>
	import Vue from 'vue';
	import Dropzone from 'dropzone';
	import { domHelper, filesize } from '@/_helpers';
	import { mediaModel } from '@/_models';
	import DzProcessor from './components/dz-processor.vue';

	export default {
		name: 'media-upload',
		model: {
			prop: 'media',
			event: 'uploaded'
		},
		props: {
			uploadText: {
				type: String,
				default: 'Datei auswählen'
			},
			uploadHelpText: {
				type: String,
				default: 'Oder eine Datei einfach per "Drag & Drop" hier loslassen.'
			},
			uploadWrongFileType: {
				type: String,
				default: 'Es sind nur JPG und PNG Bilder zugelassen'
			},
			acceptedFiles: {
				type: String,
				default: 'image/jpg,image/jpeg,image/png'
			},
			uploadUrl: {
				type: String,
				default: () => Vue.webApi.constructUrl('media', 'uploadfiles')
			},
			uploadParameter: {
				type: String,
				default: 'files',
			},
			maxFilesize: {
				type: Number,
				default: 2
			},
			media: {
				required: true,
				type: mediaModel
			}
		},
		data() {
			return {
				uploading: false,
				progress: 0,
				file: new mediaModel(),
				oldFile: new mediaModel(),
				id: null,
				currentFile: null,
				allowedUploader: null,
				addTimeout: null
			};
		},
		mounted() {
			const self = this;
			const attachTo = this.$refs['manualUpload'];

			this.id = 'ctrl' + this.getRandomInt(1, 100000);
			attachTo.appendChild(domHelper.createElementFromHTML('<div class="d-none" id="' + this.id + '"><div class="fileUploader" /><div class="fileSelectorPreview" /></div>'))
			// this dropzone is used to "manage" the files
			this.allowedUploader = new Dropzone(document.body, {
				url: 'null',
				previewsContainer: '#' + this.id + ' .fileSelectorPreview',
				clickable: attachTo,
				uploadMultiple: false,
				parallelUploads: 1,
				acceptedFiles: this.acceptedFiles,
				maxFilesize: this.maxFilesize,
				autoProcessQueue: false,
				autoDiscover: false,
				dictInvalidFileType: this.uploadWrongFileType,
				init: function () {
					this.on('addedfile', (file) => {
						self.startUpload(file);
						if (self.addTimeout) {
							window.clearTimeout(self.addTimeout);
						}
						self.addTimeout = window.setTimeout(() => self.processNextFile(), 25);
					}).on('error', function (file, msg) {
						self.error(file, msg);
						self.processNextFile(file);
					});
				}
			});
		},
		beforeDestroy() {
			this.allowedUploader && this.allowedUploader.destroy && this.allowedUploader.destroy();
			this.allowedUploader = null;
			domHelper.removeElementById('#' + this.id);
		},
		computed: {
			backgroundImage() {
				// ok, so if there is a url, download it and embed it as a data url
				if (this.file && this.file.url && !this.file.backgroundImage) {
					Vue.webApi.downloadImage(this.file.url)
						.then(dataUrl => {
							this.file.backgroundImage = 'url(' + dataUrl + ')';
						});
				}
				return this.file && this.file.backgroundImage || 'none';
			},
			hasImage() {
				return this.backgroundImage !== 'none';
			},
			size() {
				return filesize.calc(this.file.size);
			}
		},
		methods: {
			getRandomInt(min, max) {
				return Math.floor(Math.random() * (max - min)) + min;
			},

			thumbLoaded(file, url) {
				this.file.url = url;
				this.file.backgroundImage = null;
			},
			updateProgress(file, progress) {
				this.progress = progress;
			},
			uploadComplete(file, response) {
				this.file.update(response.data);

				this.progress = 100;
				this.uploading = false;
				this.currentFile = null;
				this.$emit('uploaded', this.file);
			},
			error(file, msg, show) {
				this.$toastr('error', msg);
				console.error('errrrrrrrrrrrrrrror', file, msg, show);
			},
			startUpload(file) {
				this.oldFile.update(this.file);

				this.uploading = true;
				this.progress = 0;
				this.file.url = file.dataUrl;
				this.file.backgroundImage = null;
				this.file.size = file.size;
				this.file.name = file.name;
				this.file.id = '';
				this.file.isTemp = false;
			},
			revert() {
				this.file.update(this.oldFile);
			},
			processNextFile(lastFile) {
				lastFile = lastFile || this.currentFile;
				if (lastFile != null) {
					this.allowedUploader.removeFile(lastFile);
					this.currentFile = null;
				}
				var queueLength = this.allowedUploader.getQueuedFiles().length;
				if (queueLength > 0) {
					this.currentFile = this.allowedUploader.getQueuedFiles()[queueLength - 1];
					this.$refs['processor'].addFile(this.currentFile);
				}
			}
		},
		watch: {
			media: {
				handler(value) {
					this.file.update(value);
				},
				immediate: true,
				deep: true
			}
		},
		components: {
			DzProcessor
		}
	};
</script>
