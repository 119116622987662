import { OptionTypes, TextTypes } from '@/_helpers';
import { caravanOptionModel, extraItemModel, textModel } from '@/_models';

export class caravanCategoryModel {
	constructor(data, configId) {
		this.update(data || {}, configId);
		this.uiId = 'cat_' + Math.floor(Math.random() * (1000000));
		this.lastType = 0;
		this.lastTypeText = this.optionTextAdd(0);
	}

	update(value, configId) {
		this.id = value.id || 0;
		this.readonly = value.readonly || false;
		if (this.text) {
			this.text.update(value.text);
		} else {
			this.text = new textModel(value.text || {
				type: TextTypes.Category
			});
		}
		this.configId = configId || this.configId;
		this.options = (value.options || []).map(o => new caravanOptionModel(o, this.configId));
		this.extraItems = (value.extraItems || []).map(i => new extraItemModel(i, this.configId));
		this.deleted = value.deleted || false;
		this.isPackage = value.isPackage || false;

		// other props
		this.focus = value.focus || false;
	}

	updateExtraItems(items) {
		this.extraItems = (items || []).map(i => new extraItemModel(i, this.configId));
	}

	addOption(type) {
		this.lastType = type || 0;
		this.lastTypeText = this.optionTextAdd(this.lastType);
		this.options.push(new caravanOptionModel({
			focus: true,
			type: this.lastType,
			selected: true
		}, this.configId));
	}

	optionTextAdd(type) {
		return CaravanOptionText(type) + ' hinzufügen';
	}

	toggleDelete() {
		this.deleted = !this.deleted;
	}
}

export const CaravanOptionTypes = () => [
	OptionTypes.Normal,
	OptionTypes.Included,
	OptionTypes.OnDemand,
	OptionTypes.NoExtraCharge
];

export const CaravanOptionText = type => {
	switch (type) {
		case OptionTypes.Included:
			return 'Serienausstattung';
		case OptionTypes.OnDemand:
			return 'auf Anfrage-Option';
		case OptionTypes.NoExtraCharge:
			return 'ohne Aufpreis-Option';
		case OptionTypes.MainPrice:
			return 'Modell Preis';
		default:
			return 'Normale-Option';
	}
};

export const CaravanOptionDisplayText = type => {
	switch (type) {
		case OptionTypes.Included:
			return 'Serienausstattung';
		case OptionTypes.OnDemand:
			return 'auf Anfrage';
		case OptionTypes.NoExtraCharge:
			return 'ohne Aufpreis';
		case OptionTypes.MainPrice:
			return 'Modell Preis';
		default:
			return 'Normale';
	}
};
