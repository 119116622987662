<template>
	<router-link :to="{ name: navigateTo }" :class="{ 'selected': route.active }"><icon :name="route.icon"/>{{ route.title }}</router-link>
</template>
<script>
	export default {
		name: 'navigation-link',
		props: {
			route: {
				required: true,
				type: Object
			}
		},
		computed: {
			navigateTo() {
				if (this.route.forward && this.route.children && this.route.children.length) {
					// find the first item with permissions
					const names = this.route.forward;
					var route = this.route.children.find(c => c.permission && names.indexOf(c.name) !== -1);
					if (route) {
						return route.name;
					}
				}
				return this.route.name;
			}
		}
	};
</script>
