import _ from 'lodash';

export class labelValueModel {
	constructor(data) {
		this.update(data || {});
	}

	update(value) {
		_.merge(this, value);
		this.label = value.label || '';
		this.value = value.value || '';
		this.deleted = false;
	}
}
