import { typeBaseMixin } from '@/_mixins';

export const caravanTypeMixin = {
	mixins: [typeBaseMixin],
	data() {
		return {
			lastEditTypeKey: 'caravans'
		};
	}
};
