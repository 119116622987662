import EventBus from '@/event-bus';
import store from '@/store/index';

const settingsHelper = {
	_installed: false,
	_vm: null,

	install(Vue) {
		if (this._installed) {
			return;
		}
		this._installed = true;
		this._vm = Vue;

		let $s = {
			get currency() {
				return store.state.settings.currency;
			},
			set currency(value) {
				store.commit('settings/updateItem', { key: 'currency', value: value });
			},

			get exchangeRate() {
				return store.state.settings.exchangeRate;
			},
			set exchangeRate(value) {
				store.commit('settings/updateItem', { key: 'exchangeRate', value: value });
			},

			get vat() {
				return store.state.settings.vat;
			},
			set vat(value) {
				store.commit('settings/updateItem', { key: 'vat', value: value });
			},

			get financeRate() {
				return store.state.settings.financeRate;
			},
			set financeRate(value) {
				store.commit('settings/updateItem', { key: 'financeRate', value: value });
			},

			get version() {
				return store.state.settings.version;
			},

			getPrice(currency, amount, targetCurrency, debug) {
				let result = 0;
				if (currency === (targetCurrency || this.currency)) {
					result = amount || 0;
				} else if (currency === 'EUR') {
					debug && console.log(currency, amount, this.exchangeRate, (amount && Math.round(amount * this.exchangeRate)) || 0);
					result = (amount && Math.round(amount * this.exchangeRate)) || 0;
				} else {
					debug && console.log(currency, amount, this.exchangeRate, (amount && Math.round(amount * this.exchangeRate)) || 0);
					result = (amount && Math.round(amount / this.exchangeRate)) || 0;
				}
				return parseInt(result);
			},

			update(settings) {
				store.commit('settings/update', settings);
			},

			updateVersion() {
				store.commit('settings/incrementVersion');
			},

			roundMoney(x) {
				x = x * 100;
				x = (x % 5) >= 2.5 ? parseInt(x / 5) * 5 + 5 : parseInt(x / 5) * 5;
				return x / 100;
			}
		};

		Object.defineProperty(Vue.prototype, '$s', {
			get() { return $s; }
		});

		EventBus.$on('update-settings', $s.update);
		Vue.$s = $s;
	},

};

export default settingsHelper;
