export class basicDataModel {
	constructor(data) {
		this.update(data);
	}

	update(model) {
		model = model || {};
		this.id = model.id;
		this.text = model.text;
		this.suffix = model.suffix;
		this.isStandard = model.isStandard || false;
		this.dataType = model.dataType || 0;
		this.basicDataType = model.basicDataType || 0;
		this.showInOrder = model.showInOrder || false;
		this.deleted = false;
		this.isNew = !!model.isNew;
		this.types = model.types || [];
	}
}
