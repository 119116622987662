import { ClientStatuses, SalutationTypes } from '@/_helpers';

export class clientModel {
	constructor(data) {
		this.update(data || {});
	}

	update(value) {
		this.id = value.id || 0;
		this.salutationType = value.salutationType || 0;
		this.firstname = value.firstname;
		this.surname = value.surname;
		this.companyName = value.companyName;
		this.address = value.address;
		this.street = value.street;
		this.postalCode = value.postalCode;
		this.city = value.city;
		this.country = value.country || 'Schweiz';
		this.email = value.email;
		this.telephone1 = value.telephone1;
		this.telephone2 = value.telephone2;
		this.status = value.status || ClientStatuses.Active;
		this.comment = value.comment;
		this.changedBy = value.changedBy;
		this.changedOn = value.changedOn;
	}

	get displayName() {
		let name = [];
		this.salutationType && name.push(SalutationTypes.getText(this.salutationType));
		this.firstname && name.push(this.firstname);
		this.surname && name.push(this.surname);
		//this.companyName && name.push(this.companyName);
		return name.join(' ');
	}

	get completeAddress() {
		let address = [];
		this.address && address.push(this.address);
		this.street && address.push(this.street);
		(this.postalCode || this.city) && address.push([this.postalCode, this.city].filter(s => !!s).join(', '));
		this.country && address.push(this.country);
		return address.join('\n');
	}
}
