<template>
	<span class="sortable" @click="clicked" :class="{ 'active': active }">
		{{ text }}<i :class="directionCss"></i>
		
	</span>
</template>
<script>
	export default {
		name: 'sortable-column',
		props: {
			column: String,
			text: String,
			current: String,
			descending: Boolean,
			invert: Boolean,
			numeric: Boolean,
			alpha: Boolean
		},
		computed: {
			active() {
				return this.column === this.current;
			},
			directionCss() {
				var result = ['fa'];
				var prefix = 'fa-sort-amount-';
				var invert = this.invert;
				if (this.numeric) {
					prefix = 'fa-sort-numeric-';
				} else if (this.alpha) {
					prefix = 'fa-sort-alpha-';
				} else {
					invert = !invert;
				}
				if (this.active && this.descending) {
					result.push(prefix + (invert ? 'up-alt' : 'up'));
				} else {
					result.push(prefix + (invert ? 'down-alt' : 'down'));
				}
				return result;
			}
		},
		methods: {
			clicked() {
				this.$emit('update', {
					column: this.column,
					descending: this.active ? !this.descending : false
				});
			}
		}
	};
</script>
