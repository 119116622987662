import Vue from 'vue';
import Router from 'vue-router';
import lodash from 'lodash';

import { authenticationService } from '@/_services';
import { Role } from '@/_helpers';
import Forward from '@/components/routes/forward.vue';
import EmptyRoute from '@/components/routes/empty.vue';
import NotFound from '@/components/routes/not-found.vue';
import NavContainer from '@/components/routes/nav-container.vue';
import EventBus from '../event-bus';

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    routes: [
        // Home
        {
            path: '/',
            component: NavContainer,
            children: [
                {
                    path: '',
                    component: () => import('@/components/home.vue'),
                    name: 'home',
                    meta: {
                        title: 'Home',
                        authorize: [],
                        nav: {
                            icon: 'home'
                        }
                    }
                },
                {
                    path: 'euro',
                    component: () => import('@/components/settings/euro.vue'),
                    name: 'euro',
                    meta: {
                        title: 'EURO Kurs verwalten',
                        authorize: [Role.Admin],
                        nav: {
                            icon: 'euro-sign',
                            title: 'EURO Kurs'
                        }
                    }
                },
                {
                    path: 'tax',
                    component: () => import('@/components/settings/vat.vue'),
                    name: 'tax',
                    meta: {
                        title: 'MWST verwalten',
                        authorize: [Role.Admin],
                        nav: {
                            icon: 'gavel',
                            title: 'MWST'
                        }
                    }
                },
                {
                    path: 'financing',
                    component: () => import('@/components/settings/financing.vue'),
                    name: 'financing',
                    meta: {
                        title: 'Finanzierung verwalten',
                        authorize: [Role.Admin],
                        nav: {
                            icon: 'percent',
                            title: 'Finanzierung'
                        }
                    }
                }
            ]
        },
        // users
        {
            path: '/users',
            component: NavContainer,
            children: [
                {
                    path: '',
                    component: () => import('@/components/users/list.vue'),
                    name: 'users',
                    meta: {
                        title: 'Benutzerverwaltung',
                        authorize: [Role.Admin],
                        nav: {
                            icon: 'users'
                        }
                    }
                },
                {
                    path: 'new',
                    component: () => import('@/components/users/edit.vue'),
                    name: 'user-new',
                    meta: {
                        title: 'Benutzer erstellen',
                        authorize: [Role.Admin]
                    }
                },
                {
                    path: 'edit/:id',
                    component: () => import('@/components/users/edit.vue'),
                    name: 'user-edit',
                    props: castRouteParams,
                    meta: {
                        title: 'Benutzer bearbeiten',
                        authorize: [Role.Admin]
                    }
                },
                {
                    path: 'delete/:id',
                    component: () => import('@/components/users/delete.vue'),
                    name: 'user-delete',
                    props: castRouteParams,
                    meta: {
                        title: 'Benutzer löschen',
                        authorize: [Role.Admin]
                    }
                },
                {
                    path: 'change-password/:id',
                    component: () => import('@/components/users/change-password.vue'),
                    name: 'user-change-password',
                    props: castRouteParams,
                    meta: {
                        title: 'Benutzer Kennwort ändern',
                        authorize: [Role.Admin]
                    }
                }
            ]
        },
        // sales & boat stocks
        {
            path: '/sales',
            component: NavContainer,
            children: [
                {
                    path: '',
                    component: Forward,
                    name: 'salesoverview',
                    meta: {
                        title: 'Verkauf',
                        authorize: [Role.Sales, Role.StockManager],
                        nav: {
                            icon: 'dollar-sign',
                            forward: [
                                'offers',
                                'stocks'
                            ]
                        }
                    }
                },
                {
                    path: 'clients',
                    component: NavContainer,
                    children: [
                        {
                            path: '',
                            component: () => import('@/components/clients/list.vue'),
                            name: 'clients',
                            meta: {
                                title: 'Kunden verwalten',
                                authorize: [Role.Sales],
                                nav: {
                                    title: 'Kunden',
                                    icon: 'handshake'
                                }
                            }
                        },
                        {
                            path: 'new',
                            component: () => import('@/components/clients/edit.vue'),
                            name: 'client-new',
                            meta: {
                                title: 'Kunde erstellen',
                                authorize: [Role.Sales]
                            }
                        },
                        {
                            path: 'edit/:id',
                            component: () => import('@/components/clients/edit.vue'),
                            name: 'client-edit',
                            props: castRouteParams,
                            meta: {
                                title: 'Kunde bearbeiten',
                                authorize: [Role.Sales]
                            }
                        },
                        {
                            path: 'delete/:id',
                            component: () => import('@/components/clients/delete.vue'),
                            name: 'client-delete',
                            props: castRouteParams,
                            meta: {
                                title: 'Kunde löschen',
                                authorize: [Role.Sales]
                            }
                        }
                    ]
                },
                {
                    path: 'offers',
                    component: NavContainer,
                    children: [
                        {
                            path: '',
                            component: () => import('@/components/offers/list.vue'),
                            name: 'offers',
                            meta: {
                                title: 'Offerten',
                                authorize: [Role.Sales],
                                nav: {
                                    icon: 'paper-plane'
                                }
                            }
                        },
                        {
                            path: 'new',
                            component: () => import('@/components/offers/edit.vue'),
                            name: 'offer-new',
                            props: castRouteParams,
                            meta: {
                                title: 'Offerte erstellen',
                                authorize: [Role.Sales]
                            }
                        },
                        {
                            path: 'new-from-stock/:stockId',
                            component: () => import('@/components/offers/edit.vue'),
                            name: 'offer-new-stock',
                            props: castRouteParams,
                            meta: {
                                title: 'Offerte erstellen',
                                authorize: [Role.Sales]
                            }
                        },
                        {
                            path: 'edit/:id',
                            component: () => import('@/components/offers/edit.vue'),
                            name: 'offer-edit',
                            props: castRouteParams,
                            meta: {
                                title: 'Offerte bearbeiten',
                                authorize: [Role.Sales]
                            }
                        },
                        {
                            path: 'copy/:id',
                            component: () => import('@/components/offers/edit.vue'),
                            name: 'offer-copy',
                            props: castRouteParams,
                            meta: {
                                title: 'Offerte kopieren',
                                authorize: [Role.Sales]
                            }
                        },
                        {
                            path: 'config/:id',
                            component: () => import('@/components/offers/config.vue'),
                            name: 'offer-config',
                            props: castRouteParams,
                            meta: {
                                title: 'Offerte konfigurieren',
                                authorize: [Role.Sales]
                            }
                        },
                        {
                            path: 'consitions/:id',
                            component: () => import('@/components/offers/conditions.vue'),
                            name: 'offer-conditions',
                            props: castRouteParams,
                            meta: {
                                title: 'Anschreiben & Konditionen bearbeiten',
                                authorize: [Role.Sales]
                            }
                        },
                        {
                            path: 'new-order/:id',
                            component: () => import('@/components/offers/order.vue'),
                            name: 'order-new',
                            props: castRouteParams,
                            meta: {
                                title: 'Kaufvertrag erstellen',
                                authorize: [Role.Sales]
                            }
                        },
                        {
                            path: 'edit-order/:id',
                            component: () => import('@/components/offers/order.vue'),
                            name: 'order-edit',
                            props: castRouteParams,
                            meta: {
                                title: 'Kaufvertrag bearbeiten',
                                authorize: [Role.Sales]
                            }
                        },
                        {
                            path: 'delete/:id',
                            component: () => import('@/components/offers/delete.vue'),
                            name: 'offer-delete',
                            props: castRouteParams,
                            meta: {
                                title: 'Offerte löschen',
                                authorize: [Role.Sales]
                            }
                        }
                    ]
                },
                {
                    path: 'stocks',
                    component: NavContainer,
                    children: [
                        {
                            path: '',
                            component: () => import('@/components/stocks/list.vue'),
                            name: 'stocks',
                            meta: {
                                title: 'Lagerobjekte',
                                authorize: [Role.StockManager, Role.Sales],
                                nav: {
                                    icon: 'bus-alt'
                                }
                            }
                        },
                        {
                            path: 'new-stock',
                            component: () => import('@/components/stocks/edit.vue'),
                            name: 'stock-new',
                            meta: {
                                title: 'Lagerobjekt erstellen',
                                authorize: [Role.StockManager]
                            }
                        },
                        {
                            path: 'edit/:id',
                            component: () => import('@/components/stocks/edit.vue'),
                            name: 'stock-edit',
                            props: castRouteParams,
                            meta: {
                                title: 'Lagerobjekt bearbeiten',
                                authorize: [Role.StockManager]
                            }
                        },
                        {
                            path: 'copy/:id',
                            component: () => import('@/components/stocks/edit.vue'),
                            name: 'stock-copy',
                            props: castRouteParams,
                            meta: {
                                title: 'Lagerobjekt kopieren',
                                authorize: [Role.StockManager]
                            }
                        },
                        {
                            path: 'delete/:id',
                            component: () => import('@/components/stocks/delete.vue'),
                            name: 'stock-delete',
                            props: castRouteParams,
                            meta: {
                                title: 'Lagerobjekt löschen',
                                authorize: [Role.StockManager]
                            }
                        },
                        {
                            path: 'config/:id',
                            component: () => import('@/components/stocks/config.vue'),
                            name: 'stock-config',
                            props: castRouteParams,
                            meta: {
                                title: 'Lagerobjekt konfigurieren',
                                authorize: [Role.StockManager]
                            }
                        },
                        {
                            path: 'price-view/:id',
                            component: () => import('@/components/stocks/price-view.vue'),
                            name: 'stock-price-view',
                            props: castRouteParams,
                            meta: {
                                title: 'Lagerobjekt konfigurieren',
                                authorize: [Role.StockManager]
                            }
                        }
                    ]
                }
            ]
        },
        // caravans
        {
            path: '/caravans',
            component: NavContainer,
            children: [
                {
                    path: '',
                    component: Forward,
                    name: 'caravansoverview',
                    meta: {
                        title: 'Marken & Modelle',
                        authorize: [Role.ObjectManager],
                        nav: {
                            icon: 'drafting-compass',
                            forward: ['models']
                        }
                    }
                },
                {
                    path: 'models',
                    component: NavContainer,
                    children: [
                        {
                            path: '',
                            component: () => import('@/components/caravans/list.vue'),
                            name: 'models',
                            meta: {
                                title: 'Modelle-Verwaltung',
                                authorize: [Role.ObjectManager],
                                nav: {
                                    title: 'Modelle',
                                    icon: 'bus'
                                }
                            }
                        },
                        {
                            path: 'new',
                            component: () => import('@/components/caravans/edit.vue'),
                            name: 'model-new',
                            meta: {
                                title: 'Modell erstellen',
                                authorize: [Role.ObjectManager]
                            }
                        },
                        {
                            path: 'edit/:id',
                            component: () => import('@/components/caravans/edit.vue'),
                            name: 'model-edit',
                            props: castRouteParams,
                            meta: {
                                title: 'Modell bearbeiten',
                                authorize: [Role.ObjectManager]
                            }
                        },
                        {
                            path: 'options/:id',
                            component: () => import('@/components/caravans/options.vue'),
                            name: 'model-options',
                            props: castRouteParams,
                            meta: {
                                title: 'Modell-Optionen bearbeiten',
                                authorize: [Role.ObjectManager]
                            }
                        },
                        {
                            path: 'packages/:id',
                            component: NavContainer,
                            children: [
                                {
                                    path: '',
                                    component: () => import('@/components/caravans/packages.vue'),
                                    name: 'model-packages',
                                    props: castRouteParams,
                                    meta: {
                                        title: 'Modell-Pakette verwalten',
                                        authorize: [Role.ObjectManager]
                                    }
                                },
                                {
                                    path: 'new-package',
                                    component: () => import('@/components/caravans/packages/edit.vue'),
                                    name: 'package-new',
                                    props: castRouteParams,
                                    meta: {
                                        title: 'Modell-Pakette erstellen',
                                        authorize: [Role.ObjectManager]
                                    }
                                },
                                {
                                    path: 'edit-package/:packageId',
                                    component: () => import('@/components/caravans/packages/edit.vue'),
                                    name: 'package-edit',
                                    props: castRouteParams,
                                    meta: {
                                        title: 'Modell-Pakette bearbeiten',
                                        authorize: [Role.ObjectManager]
                                    }
                                },
                                {
                                    path: 'delete-package/:packageId',
                                    component: () => import('@/components/caravans/packages/delete.vue'),
                                    name: 'package-delete',
                                    props: castRouteParams,
                                    meta: {
                                        title: 'Modell-Pakette löschen',
                                        authorize: [Role.ObjectManager]
                                    }
                                }
                            ]
                        },
                        {
                            path: 'extra-items/:id',
                            component: () => import('@/components/caravans/extra-items.vue'),
                            name: 'model-extra-items',
                            props: castRouteParams,
                            meta: {
                                title: 'Modell Zusatz-Einbauten verwalten',
                                authorize: [Role.ObjectManager]
                            }
                        },
                        {
                            path: 'delete/:id',
                            component: () => import('@/components/caravans/delete.vue'),
                            name: 'model-delete',
                            props: castRouteParams,
                            meta: {
                                title: 'Modell löschen',
                                authorize: [Role.ObjectManager]
                            }
                        }
                    ]
                },
                {
                    path: 'basic-data',
                    component: NavContainer,
                    children: [
                        {
                            path: '',
                            component: () => import('@/components/caravans/basic-data/list.vue'),
                            name: 'basic-data',
                            meta: {
                                title: 'Basisdaten verwalten',
                                authorize: [Role.ObjectManager],
                                nav: {
                                    title: 'Basisdaten',
                                    icon: 'th-list fa-rotate-180'
                                }
                            }
                        },
                        {
                            path: 'new',
                            component: () => import('@/components/caravans/basic-data/edit.vue'),
                            name: 'basic-data-new',
                            meta: {
                                title: 'Basisdaten-Eintrag erstellen',
                                authorize: [Role.ObjectManager]
                            }
                        },
                        {
                            path: 'edit/:id',
                            component: () => import('@/components/caravans/basic-data/edit.vue'),
                            name: 'basic-data-edit',
                            props: castRouteParams,
                            meta: {
                                title: 'Basisdaten-Eintrag bearbeiten',
                                authorize: [Role.ObjectManager]
                            }
                        },
                        {
                            path: 'delete/:id',
                            component: () => import('@/components/caravans/basic-data/delete.vue'),
                            name: 'basic-data-delete',
                            props: castRouteParams,
                            meta: {
                                title: 'Basisdaten-Eintrag löschen',
                                authorize: [Role.ObjectManager]
                            }
                        }
                    ]
                },
                {
                    path: 'extra-items',
                    component: NavContainer,
                    children: [
                        {
                            path: '',
                            component: () => import('@/components/extra-items/list.vue'),
                            name: 'extra-items',
                            meta: {
                                title: 'Zusatz-Einbauten verwalten',
                                authorize: [Role.ObjectManager],
                                nav: {
                                    title: 'Zusatz-Einbauten',
                                    icon: 'check-square'
                                }
                            }
                        },
                        {
                            path: 'new',
                            component: () => import('@/components/extra-items/edit.vue'),
                            name: 'extra-items-new',
                            meta: {
                                title: 'Zusatz-Einbau erstellen',
                                authorize: [Role.ObjectManager]
                            }
                        },
                        {
                            path: 'edit/:id',
                            component: () => import('@/components/extra-items/edit.vue'),
                            name: 'extra-items-edit',
                            props: castRouteParams,
                            meta: {
                                title: 'Zusatz-Einbau bearbeiten',
                                authorize: [Role.ObjectManager]
                            }
                        },
                        {
                            path: 'delete/:id',
                            component: () => import('@/components/extra-items/delete.vue'),
                            name: 'extra-items-delete',
                            props: castRouteParams,
                            meta: {
                                title: 'Zusatz-Einbau löschen',
                                authorize: [Role.ObjectManager]
                            }
                        }
                    ]
                },
                {
                    path: 'brands',
                    component: NavContainer,
                    children: [
                        {
                            path: '',
                            component: () => import('@/components/brands/list.vue'),
                            name: 'brands',
                            meta: {
                                title: 'Marken verwalten',
                                authorize: [Role.Sales],
                                nav: {
                                    title: 'Marken',
                                    icon: 'flag'
                                }
                            }
                        },
                        {
                            path: 'new',
                            component: () => import('@/components/brands/edit.vue'),
                            name: 'brand-new',
                            meta: {
                                title: 'Marke erstellen',
                                authorize: [Role.Sales]
                            }
                        },
                        {
                            path: 'edit/:id',
                            component: () => import('@/components/brands/edit.vue'),
                            name: 'brand-edit',
                            props: castRouteParams,
                            meta: {
                                title: 'Marke bearbeiten',
                                authorize: [Role.Sales]
                            }
                        },
                        {
                            path: 'delete/:id',
                            component: () => import('@/components/brands/delete.vue'),
                            name: 'brand-delete',
                            props: castRouteParams,
                            meta: {
                                title: 'Marke löschen',
                                authorize: [Role.Sales]
                            }
                        }
                    ]
                },
                {
                    path: 'types',
                    component: NavContainer,
                    children: [
                        {
                            path: '',
                            component: () => import('@/components/types/list.vue'),
                            name: 'types',
                            meta: {
                                title: 'Typen verwalten',
                                authorize: [Role.Sales],
                                nav: {
                                    title: 'Typen',
                                    icon: 'project-diagram'
                                }
                            },
                        },
                        {
                            path: 'new',
                            component: () => import('@/components/types/edit.vue'),
                            name: 'type-new',
                            meta: {
                                title: 'Typ erstellen',
                                authorize: [Role.Sales]
                            }
                        },
                        {
                            path: 'edit/:id',
                            component: () => import('@/components/types/edit.vue'),
                            name: 'type-edit',
                            props: castRouteParams,
                            meta: {
                                title: 'Typ bearbeiten',
                                authorize: [Role.Sales]
                            }
                        },
                        {
                            path: 'delete/:id',
                            component: () => import('@/components/types/delete.vue'),
                            name: 'type-delete',
                            props: castRouteParams,
                            meta: {
                                title: 'Typ löschen',
                                authorize: [Role.Sales]
                            }
                        }
                    ]
                }
            ]
        },

        // texts
        {
            path: '/texts',
            component: NavContainer,
            children: [
                {
                    path: '',
                    component: () => import('@/components/texts/list.vue'),
                    name: 'texts',
                    meta: {
                        title: 'Texte verwalten',
                        authorize: [Role.ObjectManager],
                        nav: {
                            icon: 'spell-check',
                            title: 'Texte'
                        }
                    }
                }
            ]
        },

        // account - login
        {
            path: '/login',
            component: () => import('@/components/login.vue'),
            name: 'login',
            meta: {
                title: 'Login'
            }
        },

        // account - change password
        {
            path: '/change-password',
            component: () => import('@/components/account/change-password.vue'),
            name: 'changePassword',
            meta: {
                title: 'Kennwort ändern',
                authorize: []
            }
        },

        // account - edit profile
        {
            path: '/edit-profile',
            component: () => import('@/components/account/edit-profile.vue'),
            name: 'editProfile',
            meta: {
                title: 'Profile bearbeiten',
                authorize: []
            }
        },

        {
            path: '/causeerror',
            component: () => import('../components/causeerror.vue'),
            name: 'causeerror',
            meta: {
                title: 'Test JS Error'
            }
        },

        {
            path: '/unauthorized',
            component: () => import('../components/unauthorized.vue'),
            name: 'unauthorized',
            meta: {
                title: 'Zugang verweigert!'
            }
        },


        // **********************************************
        // ***  THIS MUST ALWAYS BE THER LAST ROUTE!  ***
        // **********************************************
        {
            path: '*',
            component: NotFound,
            name: 'not-found',
            meta: {
                title: 'Seite nicht gefunden'
            }
        }
    ]
});

// cast id to number
function castRouteParams(route) {
    var result = {};
    if (route.params.id) {
        result.id = Number(route.params.id);
    }
    if (route.params.packageId) {
        result.packageId = Number(route.params.packageId);
    }
    if (route.params.stockId) {
        result.stockId = Number(route.params.stockId);
    }
    return result;
}

// authorization checked
router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const { authorize } = to.meta;
    const currentUser = authenticationService.currentUserValue;

    if (authorize) {
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return next({ name: 'login', query: { returnUrl: to.path } });
        }

        // check if route is restricted by role
        if (authorize.length && !currentUser.roles.find(r => authorize.indexOf(r) !== -1)) {
            // role not authorised so redirect to unauthorized page
            return next({ name: 'unauthorized' });
        }

        // check with the server as well
        if (['login', 'unauthorized'].indexOf(to.name) === -1) {
            Vue.webApi.post('account', 'router', {
                path: to.path
            })
                .then(
                    response => {
                        // all good, nothing to see here :)
                    },
                    error => {
                        switch (error.response.status) {
                            case 401:
                                console.warn('User must login ... redirect to login');
                                authenticationService.logout();
                                router.push({ name: 'login' });
                                break;
                            case 403:
                                console.warn('User not authorized');
                                router.push({ name: 'unauthorized' });
                                break;
                        }
                    }
                );
        }
    }

    next();
});


// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    //const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        var title = nearestWithTitle.meta.title;
        if (lodash.isFunction(title))
            title = title(nearestWithTitle);

        document.title = title + ' - Konfigurator';
    } else {
        document.title = 'Konfigurator';
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});


// long load helper
var longLoadHelper = {
    loading: null,
    timeout: null,

    stopLoading(name) {
        if (this.loading === name) {
            EventBus.$emit('long-load-stop');
        }
        this.loading = null;
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
    },

    startLoading(name) {
        this.loading = name;
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
        this.timeout = window.setTimeout(() => {
            if (this.loading === name) {
                EventBus.$emit('long-load-start');
            }
        }, 200);
    }
};

router.beforeEach((to, from, next) => {
    longLoadHelper.startLoading(to.name);
    return next();
});

router.afterEach((to) => {
    longLoadHelper.stopLoading(to.name);
});
