export class mediaModel {
	constructor(data) {
		this.update(data || {});
	}

	update(value) {
		this.id = value.id || '';
		this.name = value.name;
		this.url = value.url;
		this.backgroundImage = null;
		this.size = value.size || 0;
		this.isTemp = value.isTemp;
	}
}
