export class basicDataSelectionModel {
	constructor(data, basicDatas) {
		const model = data || {};
		basicDatas = basicDatas || [];

		const bdItem = basicDatas.find(d => d.id === model.id) || model;

		this.id = model.id;
		this.value = model.value || null;
		this.displayValue = model.displayValue || null;
		this.label = bdItem.text;
		this.suffix = bdItem.suffix;
		this.dataType = bdItem.dataType
		this.isWeight = bdItem.basicDataType === 5
		this.deleted = false
	}
}
