export const OfferStatusTypes = {
	NotSet: 0,
	InProgress: 1,
	Offer: 2,
	Order: 3,
	Deleted: 9,

	getText,
	getIcon,
	getList
};

function getText(type) {
	switch (type) {
		case OfferStatusTypes.InProgress:
			return 'in bearbeitung';
		case OfferStatusTypes.Offer:
			return 'Offerte erstellt';
		case OfferStatusTypes.Order:
			return 'Kaufvertrag erstellt';
		default:
			console.warn('OfferStatusTypes not know');
			return 'Unknown';
	}
}

function getIcon(type) {
	switch (type) {
		case OfferStatusTypes.InProgress:
			return 'edit';
		case OfferStatusTypes.Offer:
			return 'paper-plane';
		case OfferStatusTypes.Order:
			return 'shopping-cart';
		default:
			console.warn('OfferStatusTypes not know');
			return 'Unknown';
	}
}

function getList(addDeleted) {
	var result = [
		OfferStatusTypes.InProgress,
		OfferStatusTypes.Offer,
		OfferStatusTypes.Order
	];
	if (addDeleted)
		result.push(OfferStatusTypes.Deleted);

	return result.map(t => {
		return {
			value: t,
			text: getText(t)
		};
	});
}
