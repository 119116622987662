import { filterBaseModel } from '@/_models';

export class stockFilterModel extends filterBaseModel {
	constructor(data) {
		super(data);
		this.update(data || {});
	}

	update(value) {
		super.update(value);

		this.name = value.name || '';
		this.chassisNumber = value.chassisNumber || '';
		this.typeId = value.typeId || null;
	}
}
