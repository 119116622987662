export const OptionAdditionalTypes = {
	None: 0,
	Normal: 1,
	Comment: 2,

	getText,
	getList
};

function getText(type) {
	switch (type) {
		case OptionAdditionalTypes.None:
			return 'Keine weitere Informationen';
		case OptionAdditionalTypes.Normal:
			return 'Zusatzinformationen';
		case OptionAdditionalTypes.Comment:
			return 'Bemerkung';
		default:
			console.warn('OptionAdditionalTypes not know');
			return 'Unknown';
	}
}

function getList() {
	var result = [
		OptionAdditionalTypes.None,
		OptionAdditionalTypes.Normal,
		OptionAdditionalTypes.Comment
	];

	return result.map(t => {
		return {
			value: t,
			text: getText(t)
		};
	});
}
