import { labelValueModel, orderClientDataModel } from '@/_models';

export class orderModel {
	constructor(data) {
		this.update(data || {});
	}

	update(value) {
		this.offerId = value.offerId || null;
		if (this.clientData) {
			this.clientData.update(value.clientData || {});
		} else {
			this.clientData = new orderClientDataModel(value.clientData);
		}
		this.paymentConditions = value.paymentConditions || '';
		this.deliveryOptions = (value.deliveryOptions || []).map(i => new labelValueModel(i));
		this.comment = value.comment || '';
		this.location = value.location || '';
		this.date = value.date || null;
		this.signatureSeller = value.signatureSeller || '';
		this.signatureBuyer = value.signatureBuyer || '';
	}

	addDeliveryOption() {
		this.deliveryOptions.push(new labelValueModel({}));
	}

	removeEmptyDeliveryOptions() {
		this.deliveryOptions = this.deliveryOptions.filter(i => {
			return !i.deleted && ((i.label || '').trim() !== '' || (i.value || '').trim() !== '');
		});
	}
}
