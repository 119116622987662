import Vue from 'vue';

export class additionalItemModel {
	constructor(data, displayCurrency) {
		this.update(data, displayCurrency);
	}

	update(value, displayCurrency) {
		value = value || {};
		this.beforeDiscount = !!value.beforeDiscount;
		this.text = value.text;
		this.additionalText = value.additionalText;
		this.currency = value.currency;
		this.price = value.price;
		this.deleted = false;
		this.isNew = !!value.isNew;
		this.displayCurrency = value.displayCurrency || displayCurrency || null;
	}

	get getPrice() {
		return Vue.$s.getPrice(this.currency, this.price, this.displayCurrency);
	}

	get calculated() {
		return !!this.price && this.currency !== (this.displayCurrency || Vue.$s.currency);
	}
}
