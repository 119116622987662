<template>
	<b-form-group :label="label"
				  :label-for="uid"
				  :label-cols="labelCols"
				  :description="description"
				  :invalid-feedback="validator.$invalid ? validator.$message : ''"
				  :state="validator.$state"
				  :class="formClassComp">
		<b-input-group :append="append" :prepend="prepend">
			<b-input-group-prepend v-if="hasPrependSlot" is-text>
				<slot name="prepend"></slot>
			</b-input-group-prepend>
			<slot>
				<b-form-select v-if="dropdown"
							   ref="input"
							   :id="uid"
							   v-model="validator.$model"
							   :class="inputClass"
							   :options="ddOptions"
							   :state="validator.$state"
							   :disabled="disabled"
							   :readonly="readonly"
							   @focus="onFocus"
							   @change="change"
							   @blur="blur" />
				<b-form-checkbox-group v-else-if="checkboxList"
									   ref="input"
									   :id="uid"
									   v-model="validator.$model"
									   :options="options"
									   :stacked="stacked"
									   :state="validator.$state"
									   :disabled="disabled"
									   :readonly="readonly"
									   @change="change" />
				<b-form-textarea v-else-if="textarea"
								 ref="input"
								 :id="uid"
								 v-model="validator.$model"
								 :class="inputClass"
								 :placeholder="placeholder"
								 :state="validator.$state"
								 :disabled="disabled"
								 :rows="rows"
								 :max-rows="maxRows"
								 :readonly="readonly"
								 :plaintext="plaintext"
								 @focus="onFocus"
								 @change="change"
								 @blur="blur" />
				<b-form-input v-else-if="isNumber"
							  ref="input"
							  :id="uid"
							  v-model.number="validator.$model"
							  :autocomplete="autocomplete ? 'on' : 'off'"
							  :class="inputClass"
							  :placeholder="placeholder"
							  :state="validator.$state"
							  type="number"
							  :min="min"
							  :max="max"
							  :step="step"
							  :disabled="disabled"
							  :readonly="readonly"
							  :plaintext="plaintext"
							  @focus="onFocus"
							  @change="change"
							  @keyup="keyup"
							  @blur="blur" />
				<b-form-checkbox v-else-if="checkbox"
								 ref="input"
								 :id="uid"
								 :class="inputClass"
								 v-model="validator.$model"
								 name="check-button"
								 switch
								 :disabled="disabled">
					&nbsp;&nbsp;&nbsp;{{ validator.$model ? checkedValue : uncheckedValue }}
				</b-form-checkbox>
				<b-form-input v-else
							  ref="input"
							  :id="uid"
							  :autocomplete="autocomplete ? 'on' : 'off'"
							  v-model.trim="validator.$model"
							  :class="inputClass"
							  :placeholder="placeholder"
							  :state="validator.$state"
							  :type="type"
							  :disabled="disabled"
							  :readonly="readonly"
							  :plaintext="plaintext"
							  @focus="onFocus"
							  @change="change"
							  @keyup="keyup"
							  @blur="blur" />
			</slot>
			<b-input-group-append v-if="hasAppendSlot">
				<slot name="append"></slot>
			</b-input-group-append>
		</b-input-group>
		<slot name="feedback"></slot>
	</b-form-group>
</template>
<script>
	import { isArray, isString } from 'lodash';

	export default {
		name: 'input-field',
		props: {
			inputClass: String,
			label: String,
			labelCols: {
				type: String,
				default: '3'
			},
			description: String,
			placeholder: String,
			validator: {
				type: Object,
				required: true
			},
			plaintext: Boolean,
			readonly: Boolean,
			disabled: Boolean,
			dropdown: Boolean,
			firstOptionText: String,
			checkboxList: Boolean,
			stacked: Boolean,
			options: Array,
			textarea: Boolean,
			rows: String,
			maxRows: String,
			type: {
				type: String,
				default: 'text'
			},
			prepend: String,
			append: String,
			min: [String, Number],
			max: [String, Number],
			step: [String, Number],
			hasPrependSlot: Boolean,
			hasAppendSlot: Boolean,
			formClass: [String, Array],
			autocomplete: Boolean,
			checkbox: Boolean,
			checkedValue: {
				type: String,
				default: 'Ja'
			},
			uncheckedValue: {
				type: String,
				default: 'Nein'
			},
			setFocus: Boolean
		},
		computed: {
			isNumber() {
				return this.type === 'number';
			},
			formClassComp() {
				let result = [];
				if (isArray(this.formClass)) {
					result = this.formClass.slice();
				} else if (isString(this.formClass)) {
					result = this.formClass.split(' ');
				}
				(this.validator.$dirty || this.validator.$invalid) && result.push('is-dirty');
				return result;
			},
			ddOptions() {
				if (this.dropdown && this.firstOptionText) {
					let options = (this.options || []).slice();
					options.splice(0, 0, { text: this.firstOptionText, value: null, disabled: true });
					return options;
				}
				return this.options;
			}
		},
		methods: {
			focus() {
				window.setTimeout(() => {
					var target = this.$refs['input'];
					target && target.focus && target.focus();
					target && target.select && target.select();
				}, 50);
			},
			onFocus() {
				this.$emit('focus');
			},
			blur() {
				this.$emit('blur');
			},
			change() {
				this.$emit('change');
			},
			keyup(ev) {
				this.$emit('keyup', ev);
			}
		},
		watch: {
			setFocus: {
				handler(value) {
					value && this.focus();
				},
				immediate: true
            }
        }
	};
</script>
