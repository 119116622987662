<template>
	<b-dropdown :text="current + ' pro Seite'" variant="outline-secondary" right>
		<b-dropdown-item v-for="size in sizes" :key="size" @click="clicked(size)" :class="{ 'active': current === size }">{{size}}</b-dropdown-item>
	</b-dropdown>
</template>
<script>
	export default {
		name: 'page-size-dropdown',
		props: {
			current: Number
		},
		data() {
			return {
				sizes: [10, 20, 50, 100]
			}
		},
		methods: {
			clicked(size) {
				this.$emit('change', size);
			}
		}
	};
</script>
